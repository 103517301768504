import { ROLE } from '~/util/permissions'
import dayjs from 'dayjs'

export function canCreateOrder(role) {
  return role === ROLE.MANAGER || role === ROLE.ADMIN
}

export function canEditOrder(order) {
  return (
    order?.editable && // // editableがtrueになる条件は[https://www.notion.so/sendwow/d44ae8bad568458bb12c1dc457b8ff5a]を参照
    !order?.authorized_at
  )
}

export function canApproveOrder(deliveryDate, order) {
  return (
    order.is_egift ||
    dayjs(deliveryDate).isSameOrBefore(dayjs(order.shipping_date))
  )
}

export function getAssignableMembers(users) {
  return users.filter((u) => u.role === ROLE.MANAGER || u.role === ROLE.ADMIN)
}

export default {
  canCreateOrder,
  canEditOrder,
  canApproveOrder,
  getAssignableMembers,
}
