function hittest(
  t: number,
  r: number,
  b: number,
  l: number,
  x: number,
  y: number
): boolean {
  return t <= y && y <= b && l <= x && x <= r
}

function isLineIntersection(
  p0x: number,
  p0y: number,
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number,
  p3x: number,
  p3y: number
): boolean {
  const s1x = p1x - p0x
  const s1y = p1y - p0y
  const s2x = p3x - p2x
  const s2y = p3y - p2y

  const s = (-s1y * (p0x - p2x) + s1x * (p0y - p2y)) / (-s2x * s1y + s1x * s2y)
  const t = (s2x * (p0y - p2y) - s2y * (p0x - p2x)) / (-s2x * s1y + s1x * s2y)

  return s >= 0 && s <= 1 && t >= 0 && t <= 1
}

declare global {
  interface DOMRect {
    hitTestP(x: number, y: number): boolean
  }
}

SVGRect.prototype.hitTestP = function (x: number, y: number): boolean {
  const t1 = Math.floor(this.y)
  const r1 = Math.ceil(this.x + this.width)
  const b1 = Math.ceil(this.y + this.height)
  const l1 = Math.floor(this.x)

  return hittest(t1, r1, b1, l1, x, y)
}
declare global {
  interface SVGGraphicsElement {
    getTrasformedBox(): SVGRect
    hitTest(target: SVGGraphicsElement): boolean
    hitTestP(x: number, y: number): boolean
    enclosureTest(target: SVGGraphicsElement): boolean
    enclosureXTest(
      target: SVGGraphicsElement,
      ignoreLeft?: boolean,
      ignoreRight?: boolean,
      checkHit?: boolean
    ): boolean
    checkUnder(target: SVGGraphicsElement): boolean
  }
}

SVGGraphicsElement.prototype.getTrasformedBox = function (): SVGRect {
  if (this instanceof SVGSVGElement) {
    return this.viewBox.baseVal
  }

  const rect = this.getBBox()
  const svg = this.closest('svg')!
  const ctm = this.getScreenCTM()!
  const matrix = svg.getScreenCTM()?.inverse().multiply(ctm)

  const p = svg.createSVGPoint()
  p.x = rect.x
  p.y = rect.y

  const r = svg.createSVGRect()
  const pA = p.matrixTransform(matrix)
  r.x = pA.x
  r.y = pA.y

  p.x = rect.x + rect.width
  p.y = rect.y + rect.height
  const pB = p.matrixTransform(matrix)
  r.width = pB.x - pA.x
  r.height = pB.y - pA.y

  return r
}

SVGGraphicsElement.prototype.hitTest = function (
  target: SVGGraphicsElement
): boolean {
  const rect1 = this.getTrasformedBox()
  const rect2 = target.getTrasformedBox()

  const t1 = Math.floor(rect1.y) // 切り捨て
  const r1 = Math.ceil(rect1.x + rect1.width) // 切り上げ
  const b1 = Math.ceil(rect1.y + rect1.height) // 切り上げ
  const l1 = Math.floor(rect1.x) // 切り捨て

  const t2 = Math.ceil(rect2.y) // 切り上げ
  const r2 = Math.floor(rect2.x + rect2.width) // 切り捨て
  const b2 = Math.floor(rect2.y + rect2.height) // 切り捨て
  const l2 = Math.ceil(rect2.x) // 切り上げ

  // お互いの4点がもう片方の領域上に重なっているか
  return (
    isLineIntersection(l1, t1, l1, b1, l2, t2, r2, t2) || // 1左辺と2上辺
    isLineIntersection(l1, t1, l1, b1, l2, b2, r2, b2) || // 1左辺と2下辺
    isLineIntersection(r1, t1, r1, b1, l2, t2, r2, t2) || // 1右辺と2上辺
    isLineIntersection(r1, t1, r1, b1, l2, b2, r2, b2) || // 1右辺と2下辺
    isLineIntersection(l1, t1, r1, t1, l2, t2, l2, b2) || // 1上辺と2左辺
    isLineIntersection(l1, t1, r1, t1, r2, t2, r2, b2) || // 1上辺と2右辺
    isLineIntersection(l1, t1, r1, t1, l2, t2, l2, b2) || // 1下辺と2左辺
    isLineIntersection(l1, b1, r1, b1, r2, t2, r2, b2) || // 1下辺と2右辺
    hittest(t1, r1, b1, l1, l2, t2) ||
    hittest(t1, r1, b1, l1, l2, b2) ||
    hittest(t1, r1, b1, l1, r2, t2) ||
    hittest(t1, r1, b1, l1, r2, b2) ||
    hittest(t2, r2, b2, l2, l1, t1) ||
    hittest(t2, r2, b2, l2, l1, b1) ||
    hittest(t2, r2, b2, l2, r1, t1) ||
    hittest(t2, r2, b2, l2, r1, b1)
  )
}

SVGGraphicsElement.prototype.hitTestP = function (
  x: number,
  y: number
): boolean {
  return this.getTrasformedBox().hitTestP(x, y)
}

SVGGraphicsElement.prototype.enclosureTest = function (
  target: SVGGraphicsElement
): boolean {
  const rect1 = this.getTrasformedBox()
  const rect2 = target.getTrasformedBox()

  const t1 = Math.floor(rect1.y) // 切り捨て
  const r1 = Math.ceil(rect1.x + rect1.width) // 切り上げ
  const b1 = Math.ceil(rect1.y + rect1.height) // 切り上げ
  const l1 = Math.floor(rect1.x) // 切り捨て

  const t2 = Math.ceil(rect2.y) // 切り上げ
  const r2 = Math.floor(rect2.x + rect2.width) // 切り捨て
  const b2 = Math.floor(rect2.y + rect2.height) // 切り捨て
  const l2 = Math.ceil(rect2.x) // 切り上げ

  // rect2 が rect1 の内側に入っているか
  return t1 <= t2 && b2 <= b1 && l1 <= l2 && r2 <= r1
}

SVGGraphicsElement.prototype.enclosureXTest = function (
  target: SVGGraphicsElement,
  ignoreLeft?: boolean,
  ignoreRight?: boolean,
  checkHit = false
): boolean {
  const rect1 = this.getTrasformedBox()
  const rect2 = target.getTrasformedBox()

  const r1 = Math.ceil(rect1.x + rect1.width) // 切り上げ
  const l1 = Math.floor(rect1.x) // 切り捨て

  const r2 = Math.floor(rect2.x + rect2.width) // 切り捨て
  const l2 = Math.ceil(rect2.x) // 切り上げ

  // 重なりつつ、rect2の幅が rect1の幅 に収まっているか
  return (
    (!checkHit || this.hitTest(target)) &&
    (ignoreLeft || l1 <= l2) &&
    (ignoreRight || r2 <= r1)
  )
}

SVGGraphicsElement.prototype.checkUnder = function (
  target: SVGGraphicsElement
): boolean {
  const rect1 = this.getTrasformedBox()
  const rect2 = target.getTrasformedBox()

  const b1 = Math.ceil(rect1.y + rect1.height) // 切り上げ
  const b2 = Math.floor(rect2.y + rect2.height) // 切り捨て

  // rect1 が rect2 よりも下にあるか
  return b1 >= b2
}
