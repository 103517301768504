import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _setup$props$choices;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "type": _setup.props.type,
      "title": _setup.props.title,
      "width": _setup.props.width,
      "actions": _setup.props.actions,
      "valid": _setup.valid
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_setup.props.text ? _c(VCardText, {
    staticClass: "text-body-1 py-2",
    class: {
      'pt-4': !_setup.props.title
    },
    domProps: {
      "innerHTML": _vm._s(_setup.props.text)
    }
  }) : _vm._e(), _vm._v(" "), _c(VCardText, {
    staticClass: "py-2"
  }, [_vm.label ? _c('SmallLabel', {
    staticClass: "pb-1",
    attrs: {
      "label": _vm.label
    }
  }, [_vm._t("label.prepend")], 2) : _vm._e(), _vm._v(" "), (_setup$props$choices = _setup.props.choices) !== null && _setup$props$choices !== void 0 && _setup$props$choices.length ? _c(VAutocomplete, {
    ref: "selectRef",
    attrs: {
      "items": _setup.props.choices,
      "outlined": "",
      "placeholder": _vm.$t('placeholder.select'),
      "menu-props": {
        offsetY: true,
        offsetOverflow: false,
        allowOverflow: true
      }
    },
    model: {
      value: _setup.value,
      callback: function callback($$v) {
        _setup.value = $$v;
      },
      expression: "value"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };