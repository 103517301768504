import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
/* eslint-disable no-console */

import { FetchError } from 'ofetch';
var forcelogout = false;
export default defineNuxtPlugin(function (nuxtApp) {
  nuxtApp.vueApp.config.errorHandler = function (err, vm, info) {
    var _useNuxtApp = useNuxtApp(),
      $t = _useNuxtApp.$t,
      $alert = _useNuxtApp.$alert,
      $toast = _useNuxtApp.$toast;
    var router = useRouter();

    // 通信キャンセルの場合はエラーハンドリングしない
    if (/aborted/.test(err.message)) return true;
    try {
      var _ref, _err$response, _err$response2, _err$response2$_data;
      var currentRoute = router.currentRoute;
      var isLoginPage = currentRoute.name === 'login';
      var isFetchError = err instanceof FetchError;
      var statusCode = (_ref = isFetchError ? (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status : null) !== null && _ref !== void 0 ? _ref : -1;
      var errorDetail = isFetchError ? (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : (_err$response2$_data = _err$response2._data) === null || _err$response2$_data === void 0 ? void 0 : _err$response2$_data.detail : null;
      var isAuthError = [401, 403].includes(statusCode);
      var isTeamIdError = errorDetail === 'Team ID not provided.';
      if (!isLoginPage && isAuthError) {
        if (isTeamIdError) {
          useUserStore().clearTeam();
          router.push({
            path: '/teams/select',
            query: {
              next: currentRoute.fullPath
            }
          });
        } else if (!forcelogout) {
          forcelogout = true;
          $alert({
            type: 'warning',
            text: $t('error.401'),
            actions: {
              true: {
                text: $t('action.ok')
              }
            }
          }).then(function () {
            var _useUserStore$team;
            router.push({
              path: '/logout',
              query: {
                next: currentRoute.fullPath,
                team: (_useUserStore$team = useUserStore().team) === null || _useUserStore$team === void 0 ? void 0 : _useUserStore$team.id
              }
            });
            forcelogout = false;
          });
        }
        return true;
      } else if (isFetchError) {
        var _err$response$_data$d, _err$response3, _err$response3$_data, _err$response4, _err$response4$_data;
        console.error(err);
        var errorMessage = (_err$response$_data$d = (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : (_err$response3$_data = _err$response3._data) === null || _err$response3$_data === void 0 ? void 0 : _err$response3$_data.detail) !== null && _err$response$_data$d !== void 0 ? _err$response$_data$d : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : (_err$response4$_data = _err$response4._data) === null || _err$response4$_data === void 0 ? void 0 : _err$response4$_data.message;
        switch (statusCode) {
          case 500:
            errorMessage ? $alert({
              type: 'warning',
              title: $t('error.500'),
              text: $t('error.detail') + $t('error.detailMessage', [errorMessage])
            }) : $toast.error($t('error.500'));
            break;
          default:
            errorMessage ? $alert({
              type: 'warning',
              title: $t('error.general'),
              text: $t('error.detail') + $t('error.detailMessage', [errorMessage])
            }) : $toast.error($t('error.general'));
            break;
        }
        return true;
      } else {
        console.error(err);
        $toast.error($t('error.system'));
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };
  window.addEventListener('unhandledrejection', function (event) {
    event.preventDefault();
    console.log('Captured in unhandledrejection EventListener ->', event.reason);
    nextTick(function () {
      throw event.reason;
    });
  });
});