import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.timers.js";
function log() {
  var _console;
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  // eslint-disable-next-line no-console
  (_console = console).log.apply(_console, ['%cGTM', 'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;'].concat(args));
}
function _push(params) {
  var enabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (enabled) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(params);
  }
  log('push', params);
}
export default defineNuxtPlugin(function () {
  var _useRuntimeConfig = useRuntimeConfig(),
    environment = _useRuntimeConfig.environment;
  var enabled = environment != null;
  _push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  }, enabled);
  useRouter().afterEach(function (to) {
    setTimeout(function () {
      _push({
        routeName: to.name,
        pageType: 'PageView',
        pageUrl: to.fullPath,
        pageTitle: typeof document !== 'undefined' && document.title || '',
        event: 'nuxtRoute'
      }, enabled);
    }, 250);
  });
  return {
    provide: {
      gtm: {
        push: function push(params) {
          _push(params, enabled);
        }
      }
    }
  };
});