declare global {
  interface Element {
    toggleAttributeValue(
      name: string,
      value: string | undefined | null
    ): boolean
  }
}

Element.prototype.toggleAttributeValue = function (
  name: string,
  value: string | undefined | null
): boolean {
  if (value) {
    this.setAttribute(name, value)
    return true
  } else {
    this.removeAttribute(name)
    return false
  }
}
