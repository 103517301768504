import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VSystemBar, {
    staticClass: "color-surface",
    style: _setup.style,
    attrs: {
      "app": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center flex-grow-1"
  }, [_c(VIcon, {
    staticClass: "ml-4 mr-2",
    attrs: {
      "size": "20",
      "color": _setup.color
    }
  }, [_vm._v("\n        " + _vm._s(_setup.icon) + "\n      ")]), _vm._v(" "), _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis.flexible",
      value: 2,
      expression: "2",
      modifiers: {
        "flexible": true
      }
    }],
    staticClass: "text-body-2 primary-text--text"
  }, [_vm._v("\n        " + _vm._s(_vm.announcement.text) + "\n      ")]), _vm._v(" "), _vm.announcement.link ? _c('TextButton', {
    staticClass: "ml-4 mr-2",
    attrs: {
      "value": _vm.$t('action.seeMore'),
      "color": "primary",
      "small": "",
      "depressed": "",
      "href": _vm.announcement.link,
      "target": "_blank",
      "rel": "noopener noreferrer",
      "external-icon": null
    }
  }) : _vm._e(), _vm._v(" "), _c(VSpacer)], 1), _vm._v(" "), _c(VDivider)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };