import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VApp, {
    staticClass: "app"
  }, [_vm._l(_setup.announcements, function (announcement, i) {
    return _c(_setup.Announcement, {
      key: "announcement".concat(i),
      attrs: {
        "announcement": announcement,
        "num": i
      }
    });
  }), _vm._v(" "), _c(_setup.AppDrawer), _vm._v(" "), _c(VMain, [_c(_setup.PageHeader), _vm._v(" "), _c('div', {
    staticClass: "px-2 pb-10 flex-grow-1 p-relative"
  }, [_c('nuxt')], 1), _vm._v(" "), _c(_setup.PageFooter)], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };