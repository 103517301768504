import { ROLE } from '~/util/permissions'

export function canBillingEdit(role) {
  return role === ROLE.ADMIN
}

export function canTeamMemberRoleEdit(role) {
  return role === ROLE.MANAGER || role === ROLE.ADMIN
}

export function canTeamEdit(role) {
  return role === ROLE.ADMIN
}

export function editableRoles(role) {
  // NOTE: 権限に応じて変更可能なステータスを追加
  const roles = [
    {
      text: 'マネージャー',
      value: ROLE.MANAGER,
    },
    {
      text: 'メンバー',
      value: ROLE.MEMBER,
    },
  ]

  if (role === 2) {
    roles.push({
      text: 'アドミン',
      value: ROLE.ADMIN,
    })
  }

  return roles
}

export default {
  canBillingEdit,
  canTeamMemberRoleEdit,
  canTeamEdit,
  editableRoles,
}
