import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
export default defineNuxtPlugin(function (nuxtApp) {
  return {
    provide: {
      pushOrNewTab: function pushOrNewTab(to, event) {
        if (event != null && ((event.metaKey || event.ctrlKey) && event.type === 'click' || event.type === 'auxclick' && event.button === 1)) {
          window.open(to, '_blank', 'toolbar=yes');
        } else if (event == null || event.type === 'click') {
          useRouter().push(to);
        }
      },
      open: function open(value, options) {
        if (value) {
          var _options$width, _options$height, _window$open;
          // SalesforceのURLを開く場合はPCレイアウトで表示されるサイズにする
          var url = value instanceof URL ? value.toString() : value !== null && value !== void 0 ? value : '';
          var toSalesforce = /^https:\/\/.+\.my\.salesforce\.com\//.test(url);
          var option = ["width=".concat((_options$width = options === null || options === void 0 ? void 0 : options.width) !== null && _options$width !== void 0 ? _options$width : toSalesforce ? 1024 : 820), "height=".concat((_options$height = options === null || options === void 0 ? void 0 : options.height) !== null && _options$height !== void 0 ? _options$height : toSalesforce ? 760 : 600), (options === null || options === void 0 ? void 0 : options.noopener) !== false ? 'noopener' : null, (options === null || options === void 0 ? void 0 : options.noreferrer) !== false ? 'noreferrer' : null].filter(function (item) {
            return item != null;
          });
          (_window$open = window.open(value, '_blank', option.join(','))) === null || _window$open === void 0 ? void 0 : _window$open.focus();
        }
      },
      openSalesforceOauthLogin: function openSalesforceOauthLogin() {
        nuxtApp.$open("https://login.salesforce.com/services/oauth2/authorize?client_id=".concat(nuxtApp.$config.public.saClientId, "&redirect_uri=").concat(nuxtApp.$config.public.saRedirectUri, "&response_type=code"), {
          noopener: false,
          noreferrer: false
        });
      },
      openHubspotOauthLogin: function openHubspotOauthLogin() {
        nuxtApp.$open("https://app.hubspot.com/oauth/authorize?client_id=".concat(nuxtApp.$config.public.hubClientId, "&redirect_uri=").concat(nuxtApp.$config.public.hubRedirectUri, "&scope=oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read%20crm.objects.custom.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write"), {
          noopener: false,
          noreferrer: false
        });
      },
      openAboutPlaceholder: function openAboutPlaceholder() {
        nuxtApp.$open('https://sendwow.notion.site/6955336319a14af59b957b63b0b2a583');
      },
      openAboutAddressConfirmation: function openAboutAddressConfirmation() {
        nuxtApp.$open('https://sendwow.notion.site/02830da4cdf6414ba9acd0c6011bab16');
      },
      openAboutSlackIntegration: function openAboutSlackIntegration() {
        nuxtApp.$open('https://sendwow.notion.site/Slack-9541b6ca29b24eb29fd844adf4baeaea');
      },
      openAboutChatworkIntegration: function openAboutChatworkIntegration() {
        nuxtApp.$open('https://sendwow.notion.site/Chatwork-0c28769b51dc4c9e9ed9da6002ccf8d9');
      },
      openAboutSalesforceIntegration: function openAboutSalesforceIntegration() {
        nuxtApp.$open('https://sendwow.notion.site/Salesforce-bcf74ecc16314355a8b55dd07292d968');
      },
      openAboutHubspotIntegration: function openAboutHubspotIntegration() {
        nuxtApp.$open('https://sendwow.notion.site/HubSpot-bc440a172613466e84b31829b69f7129');
      },
      openAboutTeamsIntegration: function openAboutTeamsIntegration() {
        nuxtApp.$open('https://sendwow.notion.site/Teams-13877cadc8f48037938adace4c2a1d3a');
      },
      openAboutSalesforceWriteWowlink: function openAboutSalesforceWriteWowlink() {
        nuxtApp.$open('https://sendwow.notion.site/WOW-344947c42419460fa5d76a117b31653e');
      },
      openAboutSalesforceSyncSettings: function openAboutSalesforceSyncSettings() {
        nuxtApp.$open('https://sendwow.notion.site/Salesforce-1252a69a32a0406f87b213cfcfa87813');
      },
      openAboutHubSpotSyncSettings: function openAboutHubSpotSyncSettings() {
        nuxtApp.$open('https://sendwow.notion.site/for-HubSpot-250437c606e64db7aafe7d0c4335d260');
      },
      openAboutAddressIcons: function openAboutAddressIcons() {
        nuxtApp.$open('https://sendwow.notion.site/4e2d7579a6dd483ba4318e18adf28abc');
      },
      openDesiredArrivalDatePrecautions: function openDesiredArrivalDatePrecautions() {
        nuxtApp.$open('https://sendwow.notion.site/58b54c5d5e524e6cbc5080338080c94c');
      },
      openAboutSyncStatus: function openAboutSyncStatus() {
        nuxtApp.$open('https://sendwow.notion.site/b7962f57d8c84fedb9623d21270c28b6');
      },
      openAboutDestinationAddress: function openAboutDestinationAddress() {
        nuxtApp.$open('https://sendwow.notion.site/4e2d7579a6dd483ba4318e18adf28abc');
      },
      openAboutContactImport: function openAboutContactImport() {
        nuxtApp.$open('https://sendwow.notion.site/a7466f1d93c745e8a8b6695449eef5e2');
      },
      openAboutPrinting: function openAboutPrinting() {
        nuxtApp.$open('https://sendwow.notion.site/PDF-10e77cadc8f48039a1c7cbe67ff6d251');
      }
    }
  };
});