import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex align-center px-4 pt-4"
  }, [_c(VBreadcrumbs, {
    staticClass: "pl-4",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function fn() {
        return [_c(VIcon, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c(VBreadcrumbsItem, {
          attrs: {
            "exact": "",
            "disabled": item.disabled,
            "to": item.to,
            "nuxt": ""
          }
        }, [_vm._v("\n        " + _vm._s(item.text) + "\n      ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };