import Vue from 'vue'

if (!Vue.__handle_window_message_mixin__) {
  Vue.__handle_window_message_mixin__ = true
  Vue.mixin({
    mounted() {
      const func = this.$options.handleWindowMessage
      if (typeof func === 'function') {
        window.addEventListener('message', this.__receiveMessage__)
        this.$on('__receiveMessage__', func)
      }
    },
    beforeDestroy() {
      const func = this.$options.handleWindowMessage
      if (typeof func === 'function') {
        window.removeEventListener('message', this.__receiveMessage__)
        this.$off('__receiveMessage__', func)
      }
    },
    methods: {
      __receiveMessage__(event) {
        if (event.data.source?.indexOf('vue-devtools') === 0) return // Note: For Devtools
        this.$emit('__receiveMessage__', event)
      },
    },
  })
}
