export default {
  props: {
    footerMenu: {
      type: Boolean,
      default: true
    },
    publicPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    links: function links() {
      if (this.publicPage) {
        return [{
          href: 'https://sendwow.notion.site/082d0ff571e94064a357a73bfb59cea8',
          text: 'ギフトについて'
        }, {
          href: 'https://sendwow.notion.site/f9c2b24683e54b0f8a027aced0b5959d',
          text: '寄付について'
        }, {
          href: 'https://smapo.co/privacy/',
          text: 'プライバシーポリシー'
        }, {
          href: 'https://sendwow.notion.site/11a77cadc8f480e8a45afb20e62a2702',
          text: '商標について'
        }];
      } else {
        return [{
          href: 'https://sendwow.notion.site/SendWOW-c91eb7472aea4ec988a52915b7b637f4',
          text: 'ヘルプ'
        }, {
          href: 'https://smapo.co/privacy/',
          text: 'プライバシーポリシー'
        }, {
          href: 'https://sendwow.notion.site/SendWOW-f74e8dcade7546d5b061b1d5233f502c',
          text: '変更履歴'
        }, {
          href: 'https://sendwow.notion.site/11a77cadc8f480e8a45afb20e62a2702',
          text: '商標について'
        }];
      }
    }
  }
};