import "core-js/modules/es.function.name.js";
export default function (_ref) {
  var redirect = _ref.redirect,
    error = _ref.error,
    route = _ref.route,
    $fromExtension = _ref.$fromExtension,
    $canOpenFromExtension = _ref.$canOpenFromExtension,
    $config = _ref.$config,
    $isPublicPage = _ref.$isPublicPage;
  var isProduction = $config.public.environment === 'production';
  var _useUserStore = useUserStore(),
    user = _useUserStore.user,
    currentTeam = _useUserStore.team;

  // SVG用アカウントのみアクセス可能
  if (route.path.indexOf('/tools/print') === 0) {
    return !isProduction || (user === null || user === void 0 ? void 0 : user.email) === 'dev@smapo.co' ? null : error({
      statusCode: 404
    });
  } else if (route.path.indexOf('/tools') === 0) {
    // ツールページはデバッグビルドまたはステージングの場合のみ有効
    return !isProduction ? null : error({
      statusCode: 404
    });
  }
  var defaultPath = $fromExtension() ? '/extension' : '/contact';
  var toLoginPage = route.name === 'login' || route.name === 'teams-select';
  var toLogoutPage = route.name === 'logout';
  var toExtensionPage = route.path.indexOf('/extension') === 0;
  var publicPage = $isPublicPage(route.name);
  var isAccessiblePage = toLoginPage || toLogoutPage || $fromExtension() === toExtensionPage || publicPage;
  if ($fromExtension() && !$canOpenFromExtension()) {
    return error({
      statusCode: 403,
      message: 'Sorry, access denied.'
    });
  }
  if (route.name === null || !isAccessiblePage) {
    return redirect(defaultPath);
  }
  if (route.name === 'login') {
    if (user) {
      if (!currentTeam) {
        return redirect('/teams/select');
      } else {
        return redirect(defaultPath);
      }
    } else {
      return;
    }
  }
  if (route.name === 'logout') {
    if (user) {
      return;
    } else {
      return redirect('/login');
    }
  }
  if (publicPage) {
    return;
  }
  if (!user) {
    return redirect('/login');
  }
  if (!currentTeam && route.name !== 'teams-select') {
    return redirect('/teams/select');
  }
}