import log from 'loglevel';
var originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
  var rawMethod = originalFactory(methodName, logLevel, loggerName);
  return function (message) {
    if (methodName === 'debug') rawMethod("\x1B[34m".concat(message, "\x1B[0m"));else if (methodName === 'info') rawMethod("\x1B[32m".concat(message, "\x1B[0m"));else rawMethod("".concat(message));
  };
};
if (process.env.NODE_ENV === 'production') {
  log.setLevel('warn');
} else {
  log.setLevel('trace');
}
export default defineNuxtPlugin(function () {
  return {
    provide: {
      log: log
    }
  };
});