import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
export default defineNuxtPlugin(function () {
  return {
    provide: {
      sleep: function sleep(ms) {
        return new Promise(function (resolve) {
          return setTimeout(resolve, ms);
        });
      }
    }
  };
});