/* eslint-disable @typescript-eslint/no-explicit-any */

import day from './day';
import formatter from './formatter';
import historystate from './historystate';
import i18n from './i18n';
import logger from './logger';
import model from './model';
import open from './open';
import route from './route';
import sleep from './sleep';
import unit from './unit';
import utils from './utils';
import validate from './validate';
export default function (ctx, inject) {
  day(ctx, inject);
  formatter(ctx, inject);
  historystate(ctx, inject);
  i18n(ctx, inject);
  logger(ctx, inject);
  model(ctx, inject);
  open(ctx, inject);
  route(ctx, inject);
  sleep(ctx, inject);
  unit(ctx, inject);
  utils(ctx, inject);
  validate(ctx, inject);
}