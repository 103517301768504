import "core-js/modules/es.array.includes.js";
import dayjs, { extend, locale } from 'dayjs';
import ja from 'dayjs/locale/ja';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
locale(ja);
extend(isSameOrAfter);
extend(isSameOrBefore);
extend(relativeTime);
extend(utc);
export default defineNuxtPlugin(function (nuxtApp) {
  return {
    provide: {
      today: dayjs().format('YYYY-MM-DD'),
      nextYear: dayjs().add(1, 'years').format('YYYY-MM-DD'),
      date: function date(value) {
        return nuxtApp.$formatDate(value, 'YYYY/MM/DD');
      },
      datetime: function datetime(value) {
        return nuxtApp.$formatDate(value, 'YYYY/MM/DD HH:mm');
      },
      month: function month(value) {
        return nuxtApp.$formatDate(value, 'YYYY/MM');
      },
      time: function time(value) {
        return nuxtApp.$formatDate(value, 'HH:mm');
      },
      iso8601: function iso8601(value) {
        if (value == null) return null;
        var m = dayjs(value);
        if (m.isValid()) {
          return m.local().format();
        } else {
          return null;
        }
      },
      fromNow: function fromNow(value) {
        if (value == null) return null;
        var m = dayjs(value);
        if (m.isValid()) {
          return m.locale('ja').fromNow();
        } else {
          return null;
        }
      },
      daysFromNow: function daysFromNow(days, format) {
        return dayjs().add(days, 'days').startOf('day').format(format);
      },
      workingDaysAfter: function workingDaysAfter(days, format) {
        var date = dayjs();
        if (days < 0) {
          var daysAdded = 0;
          while (daysAdded > days) {
            date = date.add(-1, 'days');
            if (![0, 6].includes(date.day())) {
              daysAdded--;
            }
          }
        } else if (days === 0) {
          while ([0, 6].includes(date.day())) {
            date = date.add(1, 'days');
          }
        } else {
          var _daysAdded = 0;
          while (_daysAdded < days) {
            date = date.add(1, 'days');
            if (![0, 6].includes(date.day())) {
              _daysAdded++;
            }
          }
        }
        return date.startOf('day').format(format);
      },
      formatDate: function formatDate(value, format) {
        if (value == null) return null;
        var m = dayjs(value);
        if (m.isValid()) {
          return m.format(format);
        } else {
          return null;
        }
      },
      reformatDate: function reformatDate(date, srcformat, dstformat) {
        if (date == null) return null;
        var m = dayjs(date, srcformat);
        if (m.isValid()) {
          return m.format(dstformat);
        } else {
          return null;
        }
      }
    }
  };
});