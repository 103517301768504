import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ea9a680 = () => interopDefault(import('../src/pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _42b9c296 = () => interopDefault(import('../src/pages/auth_callback.vue' /* webpackChunkName: "pages/auth_callback" */))
const _54b001ac = () => interopDefault(import('../src/pages/campaign/index.vue' /* webpackChunkName: "pages/campaign/index" */))
const _2ae05c5c = () => interopDefault(import('../src/pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _1268357b = () => interopDefault(import('../src/pages/extension/index.vue' /* webpackChunkName: "pages/extension/index" */))
const _04d36fa2 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3aa8e5be = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5367d047 = () => interopDefault(import('../src/pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _8bc2e302 = () => interopDefault(import('../src/pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _d8658306 = () => interopDefault(import('../src/pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6a41d0fb = () => interopDefault(import('../src/pages/touch/index.vue' /* webpackChunkName: "pages/touch/index" */))
const _150ff03a = () => interopDefault(import('../src/pages/campaign/send/index.vue' /* webpackChunkName: "pages/campaign/send/index" */))
const _562e53b1 = () => interopDefault(import('../src/pages/extension/error.vue' /* webpackChunkName: "pages/extension/error" */))
const _47522f6a = () => interopDefault(import('../src/pages/settings/callback.vue' /* webpackChunkName: "pages/settings/callback" */))
const _6192bb68 = () => interopDefault(import('../src/pages/teams/select.vue' /* webpackChunkName: "pages/teams/select" */))
const _73425264 = () => interopDefault(import('../src/pages/tools/components/index.vue' /* webpackChunkName: "pages/tools/components/index" */))
const _7953d610 = () => interopDefault(import('../src/pages/tools/editor.vue' /* webpackChunkName: "pages/tools/editor" */))
const _2619bf04 = () => interopDefault(import('../src/pages/tools/extension.vue' /* webpackChunkName: "pages/tools/extension" */))
const _a125bbe4 = () => interopDefault(import('../src/pages/tools/theme.vue' /* webpackChunkName: "pages/tools/theme" */))
const _d7a8a9ee = () => interopDefault(import('../src/pages/touch/new.vue' /* webpackChunkName: "pages/touch/new" */))
const _3d5bb919 = () => interopDefault(import('../src/pages/campaign/send/check.vue' /* webpackChunkName: "pages/campaign/send/check" */))
const _56dbe65a = () => interopDefault(import('../src/pages/extension/campaign/send/index.vue' /* webpackChunkName: "pages/extension/campaign/send/index" */))
const _614491fe = () => interopDefault(import('../src/pages/extension/contact/send/index.vue' /* webpackChunkName: "pages/extension/contact/send/index" */))
const _1a02172e = () => interopDefault(import('../src/pages/settings/integration/chatwork.vue' /* webpackChunkName: "pages/settings/integration/chatwork" */))
const _0d4844b2 = () => interopDefault(import('../src/pages/settings/integration/hubspot.vue' /* webpackChunkName: "pages/settings/integration/hubspot" */))
const _21db6b42 = () => interopDefault(import('../src/pages/settings/integration/salesforce.vue' /* webpackChunkName: "pages/settings/integration/salesforce" */))
const _a25db980 = () => interopDefault(import('../src/pages/settings/integration/slack.vue' /* webpackChunkName: "pages/settings/integration/slack" */))
const _7d6e8a66 = () => interopDefault(import('../src/pages/settings/integration/teams.vue' /* webpackChunkName: "pages/settings/integration/teams" */))
const _1c75be09 = () => interopDefault(import('../src/pages/extension/campaign/send/check.vue' /* webpackChunkName: "pages/extension/campaign/send/check" */))
const _326d9f70 = () => interopDefault(import('../src/pages/extension/campaign/send/complete.vue' /* webpackChunkName: "pages/extension/campaign/send/complete" */))
const _17416837 = () => interopDefault(import('../src/pages/extension/contact/send/check.vue' /* webpackChunkName: "pages/extension/contact/send/check" */))
const _417a2f5a = () => interopDefault(import('../src/pages/extension/contact/send/complete.vue' /* webpackChunkName: "pages/extension/contact/send/complete" */))
const _2e54d19d = () => interopDefault(import('../src/pages/tools/print/_id.vue' /* webpackChunkName: "pages/tools/print/_id" */))
const _65216e30 = () => interopDefault(import('../src/pages/accounts/_id.vue' /* webpackChunkName: "pages/accounts/_id" */))
const _62c077dc = () => interopDefault(import('../src/pages/campaign/_id.vue' /* webpackChunkName: "pages/campaign/_id" */))
const _473be947 = () => interopDefault(import('../src/pages/contact/_id/index.vue' /* webpackChunkName: "pages/contact/_id/index" */))
const _04ac24a7 = () => interopDefault(import('../src/pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _ff1453ba = () => interopDefault(import('../src/pages/touch/_id.vue' /* webpackChunkName: "pages/touch/_id" */))
const _7a247ff4 = () => interopDefault(import('../src/pages/wow/_id/index.vue' /* webpackChunkName: "pages/wow/_id/index" */))
const _0742e7e6 = () => interopDefault(import('../src/pages/contact/_id/send/index.vue' /* webpackChunkName: "pages/contact/_id/send/index" */))
const _7078f20c = () => interopDefault(import('../src/pages/wow/_id/complete_donation.vue' /* webpackChunkName: "pages/wow/_id/complete_donation" */))
const _4f26991c = () => interopDefault(import('../src/pages/contact/_id/send/check.vue' /* webpackChunkName: "pages/contact/_id/send/check" */))
const _bc6aa20e = () => interopDefault(import('~/pages/contact/_id/send/index.vue' /* webpackChunkName: "" */))
const _2b4ee116 = () => interopDefault(import('~/pages/touch/new.vue' /* webpackChunkName: "" */))
const _366ac890 = () => interopDefault(import('~/pages/settings/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounts",
    component: _5ea9a680,
    name: "accounts"
  }, {
    path: "/auth_callback",
    component: _42b9c296,
    name: "auth_callback"
  }, {
    path: "/campaign",
    component: _54b001ac,
    name: "campaign"
  }, {
    path: "/contact",
    component: _2ae05c5c,
    name: "contact"
  }, {
    path: "/extension",
    component: _1268357b,
    name: "extension"
  }, {
    path: "/login",
    component: _04d36fa2,
    name: "login"
  }, {
    path: "/logout",
    component: _3aa8e5be,
    name: "logout"
  }, {
    path: "/marketplace",
    component: _5367d047,
    name: "marketplace"
  }, {
    path: "/orders",
    component: _8bc2e302,
    name: "orders"
  }, {
    path: "/settings",
    component: _d8658306,
    name: "settings"
  }, {
    path: "/touch",
    component: _6a41d0fb,
    name: "touch"
  }, {
    path: "/campaign/send",
    component: _150ff03a,
    name: "campaign-send"
  }, {
    path: "/extension/error",
    component: _562e53b1,
    name: "extension-error"
  }, {
    path: "/settings/callback",
    component: _47522f6a,
    name: "settings-callback"
  }, {
    path: "/teams/select",
    component: _6192bb68,
    name: "teams-select"
  }, {
    path: "/tools/components",
    component: _73425264,
    name: "tools-components"
  }, {
    path: "/tools/editor",
    component: _7953d610,
    name: "tools-editor"
  }, {
    path: "/tools/extension",
    component: _2619bf04,
    name: "tools-extension"
  }, {
    path: "/tools/theme",
    component: _a125bbe4,
    name: "tools-theme"
  }, {
    path: "/touch/new",
    component: _d7a8a9ee,
    name: "touch-new"
  }, {
    path: "/campaign/send/check",
    component: _3d5bb919,
    name: "campaign-send-check"
  }, {
    path: "/extension/campaign/send",
    component: _56dbe65a,
    name: "extension-campaign-send"
  }, {
    path: "/extension/contact/send",
    component: _614491fe,
    name: "extension-contact-send"
  }, {
    path: "/settings/integration/chatwork",
    component: _1a02172e,
    name: "settings-integration-chatwork"
  }, {
    path: "/settings/integration/hubspot",
    component: _0d4844b2,
    name: "settings-integration-hubspot"
  }, {
    path: "/settings/integration/salesforce",
    component: _21db6b42,
    name: "settings-integration-salesforce"
  }, {
    path: "/settings/integration/slack",
    component: _a25db980,
    name: "settings-integration-slack"
  }, {
    path: "/settings/integration/teams",
    component: _7d6e8a66,
    name: "settings-integration-teams"
  }, {
    path: "/extension/campaign/send/check",
    component: _1c75be09,
    name: "extension-campaign-send-check"
  }, {
    path: "/extension/campaign/send/complete",
    component: _326d9f70,
    name: "extension-campaign-send-complete"
  }, {
    path: "/extension/contact/send/check",
    component: _17416837,
    name: "extension-contact-send-check"
  }, {
    path: "/extension/contact/send/complete",
    component: _417a2f5a,
    name: "extension-contact-send-complete"
  }, {
    path: "/tools/print/:id?",
    component: _2e54d19d,
    name: "tools-print-id"
  }, {
    path: "/accounts/:id",
    component: _65216e30,
    name: "accounts-id"
  }, {
    path: "/campaign/:id",
    component: _62c077dc,
    name: "campaign-id"
  }, {
    path: "/contact/:id",
    component: _473be947,
    name: "contact-id"
  }, {
    path: "/orders/:id",
    component: _04ac24a7,
    name: "orders-id"
  }, {
    path: "/touch/:id",
    component: _ff1453ba,
    name: "touch-id"
  }, {
    path: "/wow/:id",
    component: _7a247ff4,
    name: "wow-id"
  }, {
    path: "/contact/:id/send",
    component: _0742e7e6,
    name: "contact-id-send"
  }, {
    path: "/wow/:id?/complete_donation",
    component: _7078f20c,
    name: "wow-id-complete_donation"
  }, {
    path: "/contact/:id/send/check",
    component: _4f26991c,
    name: "contact-id-send-check"
  }, {
    path: "/orders/:order_id/edit",
    component: _bc6aa20e,
    name: "orders-id-edit"
  }, {
    path: "/touch/:id/edit",
    component: _2b4ee116,
    name: "touch-id-edit"
  }, {
    path: "/settings/team-info",
    component: _366ac890,
    name: "settings-team-info"
  }, {
    path: "/settings/message-template",
    component: _366ac890,
    name: "settings-message-template"
  }, {
    path: "/company",
    redirect: "/accounts"
  }, {
    path: "/account",
    redirect: "/settings"
  }, {
    path: "/account/callback",
    redirect: "/settings/callback"
  }, {
    path: "/account/integration/chatwork",
    redirect: "/settings/integration/chatwork"
  }, {
    path: "/account/integration/salesforce",
    redirect: "/settings/integration/salesforce"
  }, {
    path: "/account/integration/slack",
    redirect: "/settings/integration/slack"
  }, {
    path: "/company/:id",
    redirect: "/accounts/:id"
  }, {
    path: "/account/team-info",
    redirect: "/settings/team-info"
  }, {
    path: "/account/message-template",
    redirect: "/settings/message-template"
  }, {
    path: "/print/:id",
    redirect: "/tools/print/:id"
  }, {
    path: "/report/contact",
    redirect: "/orders"
  }, {
    path: "/report/contact/:id",
    redirect: "/orders/:id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
