import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VApp, [_c(VMain, [_c('div', {
    staticClass: "px-2 pb-10 flex-grow-1 p-relative"
  }, [_c('nuxt')], 1), _vm._v(" "), _c('PageFooter', {
    attrs: {
      "footer-menu": !_vm.$isLoginPage(),
      "public-page": _vm.$isPublicPage()
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };