import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VDialog, {
    attrs: {
      "max-width": _vm.width ? null : 460,
      "width": _vm.width,
      "content-class": _vm.clazz,
      "persistent": "",
      "scrollable": "",
      "no-click-animation": ""
    },
    on: {
      "click:outside": function clickOutside($event) {
        !_vm.persistent && _setup.onClick(false);
      }
    },
    model: {
      value: _setup.dialog,
      callback: function callback($$v) {
        _setup.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.loading === true ? _c('Loading') : _vm._e(), _vm._v(" "), _c(VCard, [_setup.props.title ? _c(VCardTitle, {
    staticClass: "flex-nowrap align-center"
  }, [_vm.type ? _c(VIcon, {
    staticClass: "mb-0.5 mr-2",
    attrs: {
      "color": _vm.type
    }
  }, [_vm._v("\n        " + _vm._s(_vm.type === 'info' ? 'mdi-information-outline' : _vm.type === 'warning' ? 'mdi-alert' : _vm.type === 'error' ? 'mdi-information' : '') + "\n      ")]) : _vm._e(), _vm._v(" "), _vm._t("title.prepend"), _vm._v(" "), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_setup.props.title))]), _vm._v(" "), _vm._t("title.append"), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.onClick(false);
      }
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1)], 2) : _vm._e(), _vm._v(" "), _vm.$slots.default ? [_vm._t("default")] : _vm._e(), _vm._v(" "), _setup.actions.length ? _c(VCardActions, {
    staticClass: "tw-gap-2"
  }, [!_setup.hasSpacer ? _c(VSpacer) : _vm._e(), _vm._v(" "), _vm._l(_setup.actions, function (action) {
    return [action.key == 'spacer' ? _c(VSpacer, {
      key: action.key
    }) : _c('button', {
      key: action.key,
      staticClass: "tw-btn !tw-p-2",
      class: {
        '!tw-text-subtitle-1': action.key === true,
        '!tw-text-body-1': action.key !== true,
        '!tw-btn-text-error': action.color === 'error',
        '!tw-btn-text-primary': action.color === 'primary',
        '!tw-btn-text-secondary': action.color !== 'primary' && action.color !== 'error'
      },
      attrs: {
        "type": "button",
        "disabled": action.disabled
      },
      on: {
        "click": function click($event) {
          return _setup.onClick(action);
        }
      }
    }, [_vm._v("\n          " + _vm._s(action.text) + "\n        ")])];
  })], 2) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };