import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VBtn } from 'vuetify/lib';
export default {
  props: _objectSpread(_objectSpread({}, VBtn.options.props), {}, {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    externalIcon: {
      type: String,
      default: 'mdi-open-in-new'
    }
  }),
  computed: {
    attributes: function attributes() {
      return _objectSpread(_objectSpread(_objectSpread({}, this.$attrs), this.$options.propsData), {}, {
        icon: null,
        small: !this.xSmall
      });
    },
    isExternalLink: function isExternalLink() {
      try {
        var _this$href;
        var parsed = new URL((_this$href = this.href) !== null && _this$href !== void 0 ? _this$href : '');
        return parsed.host !== null && parsed.host !== location.host;
      } catch (e) {
        return false;
      }
    }
  }
};