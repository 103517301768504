var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "secondary-text--text d-flex align-center",
    class: {
      'text-subtitle-2': !_vm.xSmall,
      'text-subtitle-3': _vm.xSmall
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))]), _vm._v(" "), _vm.required ? _c('RequiredIcon', {
    staticClass: "ml-0.5"
  }) : _vm._e(), _vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };