/**
 * アクセス権限リスト
 * https://www.notion.so/sendwow/62eaaa691e72479bbbdcc841568dfca2
 * {
 *   text: 'マネージャー',
 *   value: 0,
 * },
 * {
 *   text: 'メンバー',
 *   value: 1,
 * },
 * {
 *   text: 'アドミン',
 *   value: 2,
 * },
 */
export const ROLE = {
  MANAGER: 0,
  MEMBER: 1,
  ADMIN: 2,
}

export default ROLE
export * from '~/util/permissions/orders'
export * from '~/util/permissions/account/account'
export * from '~/util/permissions/account/template'
export * from '~/util/permissions/account/notification'
