import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.trim.js";
import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';
export default defineNuxtPlugin(function (nuxtApp) {
  return {
    provide: {
      isValidEmail: function isValidEmail(value) {
        return isEmail(value !== null && value !== void 0 ? value : '');
      },
      isValidEmailOrEmpty: function isValidEmailOrEmpty(value) {
        return (value !== null && value !== void 0 ? value : '') === '' || isEmail(value !== null && value !== void 0 ? value : '');
      },
      isValidUrl: function isValidUrl(value) {
        return /^https?:\/\/localhost/.test(value !== null && value !== void 0 ? value : '') || isURL(value !== null && value !== void 0 ? value : '', {
          protocols: ['http', 'https'],
          require_valid_protocol: true,
          require_protocol: true
        });
      },
      isValidUrlOrEmpty: function isValidUrlOrEmpty(value) {
        return (value !== null && value !== void 0 ? value : '') === '' || /^https?:\/\/localhost/.test(value !== null && value !== void 0 ? value : '') || isURL(value !== null && value !== void 0 ? value : '', {
          protocols: ['http', 'https'],
          require_valid_protocol: true,
          require_protocol: true
        });
      },
      isValidSlackWebhook: function isValidSlackWebhook(value) {
        return isURL(value !== null && value !== void 0 ? value : '') && (value === null || value === void 0 ? void 0 : value.indexOf('https://hooks.slack.com/services/')) === 0;
      },
      isValidTeamsWebhook: function isValidTeamsWebhook(value) {
        return isURL(value !== null && value !== void 0 ? value : '') && /^https?:\/\/prod-\d+\.[a-z]+\.logic.azure.com(:443)?\/workflows\/[0-9a-f]+\/triggers\/manual\/paths\/invoke/.test(value !== null && value !== void 0 ? value : '');
      },
      isValidZipcode: function isValidZipcode(value) {
        var allowHyphen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        return /^\d{7}$/.test(value !== null && value !== void 0 ? value : '') || allowHyphen && /^\d{3}-\d{4}$/.test(value !== null && value !== void 0 ? value : '');
      },
      isValidZipcodeOrEmpty: function isValidZipcodeOrEmpty(value) {
        var allowHyphen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        return /^(?:\d{7}|)$/.test(value !== null && value !== void 0 ? value : '') || allowHyphen && /^(?:\d{3}-\d{4}|)$/.test(value !== null && value !== void 0 ? value : '');
      },
      isEmptyAddress: function isEmptyAddress(value) {
        var _ref = value !== null && value !== void 0 ? value : {},
          postcode = _ref.postcode,
          pref = _ref.pref,
          city = _ref.city,
          street1 = _ref.street1;
        return !postcode && !pref && !city && !street1;
      },
      isFillAddress: function isFillAddress(value) {
        var _ref2 = value !== null && value !== void 0 ? value : {},
          postcode = _ref2.postcode,
          pref = _ref2.pref,
          city = _ref2.city,
          street1 = _ref2.street1;
        return !!postcode && !!pref && !!city && !!street1;
      },
      noInputAddressText: function noInputAddressText(value) {
        var _ref3 = value !== null && value !== void 0 ? value : {},
          postcode = _ref3.postcode,
          pref = _ref3.pref,
          city = _ref3.city,
          street1 = _ref3.street1;
        return [postcode !== null && postcode !== void 0 && postcode.trim() ? null : this.$t('postcode'), pref !== null && pref !== void 0 && pref.trim() ? null : this.$t('pref'), city !== null && city !== void 0 && city.trim() ? null : this.$t('city'), street1 !== null && street1 !== void 0 && street1.trim() ? null : this.$t('street1')].filter(function (item) {
          return item != null;
        }).join(' / ');
      }
    }
  };
});