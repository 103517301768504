import { throttle } from 'lodash-es';
export default defineNuxtPlugin(function (nuxt) {
  nuxt.vueApp.directive('resize-observer', {
    bind: function bind(el, binding, vnode) {
      var _vnode$resizeObserver;
      var func = binding.value;
      if (typeof func !== 'function') return;
      vnode.resizeObserver = new ResizeObserver(throttle(function () {
        nextTick(function () {
          func({
            width: el.clientWidth,
            height: el.clientHeight,
            el: el,
            vnode: vnode
          });
        });
      }, 200, {
        trailing: true
      }));
      (_vnode$resizeObserver = vnode.resizeObserver) === null || _vnode$resizeObserver === void 0 ? void 0 : _vnode$resizeObserver.observe(el);
    },
    unbind: function unbind(el, binding, vnode) {
      var _vnode$resizeObserver2;
      (_vnode$resizeObserver2 = vnode.resizeObserver) === null || _vnode$resizeObserver2 === void 0 ? void 0 : _vnode$resizeObserver2.unobserve(el);
      vnode.resizeObserver = null;
    }
  });
});