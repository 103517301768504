export default defineNuxtPlugin(function () {
  return {
    provide: {
      t: function t(key, values) {
        var _useNuxtApp = useNuxtApp(),
          $i18n = _useNuxtApp.$i18n;
        return $i18n.t(key, values);
      },
      langData: function langData() {
        var _useNuxtApp2 = useNuxtApp(),
          $i18n = _useNuxtApp2.$i18n;
        return $i18n.getLocaleMessage($i18n.locale);
      }
    }
  };
});