import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export function formatZipcode(value) {
  // 郵便マーク有無とハイフン有無含む7桁の数字かを確認
  if (!/^[〒〠〶]?[0-9０-９]{3}[ー\-−]?[0-9０-９]{4}$/.test(value)) return value;
  var replaceZipcode = value.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0); // 全角を半角に変換
  });
  return replaceZipcode === null || replaceZipcode === void 0 ? void 0 : replaceZipcode.replace(/[〒〠〶ー\-−]/g, ''); // 郵便マークとハイフン削除
}
export default defineNuxtPlugin(function (_ref) {
  var vueApp = _ref.vueApp;
  return {
    provide: {
      singleLine: function singleLine(value) {
        return value.replace(/[\n\r]/g, '');
      },
      formatZipcode: formatZipcode
    }
  };
});