import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import { ROLE } from '~/util/permissions';
export default defineNuxtPlugin(function (_ref) {
  var _user$id, _company$app_id, _team$id, _Object$keys$find;
  var $gtm = _ref.$gtm,
    $fullName = _ref.$fullName;
  var _useUserStore = useUserStore(),
    user = _useUserStore.user,
    team = _useUserStore.team,
    company = _useUserStore.company,
    role = _useUserStore.userRole;
  $gtm.push({
    user_id: (_user$id = user === null || user === void 0 ? void 0 : user.id) !== null && _user$id !== void 0 ? _user$id : null,
    // user_email: user?.email ?? null,
    // user_name: user ? $fullName(user) : null,
    company_id: (_company$app_id = company === null || company === void 0 ? void 0 : company.app_id) !== null && _company$app_id !== void 0 ? _company$app_id : null,
    // company_name: company?.company_name ?? null,
    team_id: (_team$id = team === null || team === void 0 ? void 0 : team.id) !== null && _team$id !== void 0 ? _team$id : null,
    // team_name: team?.name ?? null,
    role: (_Object$keys$find = Object.keys(ROLE).find(function (key) {
      return ROLE[key] === role;
    })) !== null && _Object$keys$find !== void 0 ? _Object$keys$find : null
  });
  $gtm.push({
    event: 'initialized'
  });
});