import SecureLS from 'secure-ls';
var ls = new SecureLS({
  isCompression: false
});
export var useToolsStore = defineStore('tools', function () {
  var svgSnapshot = ref(null);
  function saveSvgSnapshot(svg) {
    svgSnapshot.value = svg;
  }
  return {
    svgSnapshot: svgSnapshot,
    saveSvgSnapshot: saveSvgSnapshot
  };
}, {
  persist: process.env.NODE_ENV === 'production' ? {
    storage: {
      getItem: function getItem(key) {
        return ls.get(key);
      },
      setItem: function setItem(key, value) {
        return ls.set(key, value);
      }
    }
  } : true
});