import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { showTooltip, hideTooltip } from './tooltip';
var _update = function update(el, binding) {
  if (binding.value === false) return;
  if (el.classList.contains('ellipsis')) return;

  // ellipsisクラスを追加
  el.classList.add('ellipsis');
  var lines = parseInt(binding.value, 10);
  lines = isNaN(lines) ? 1 : lines;
  if (lines > 1) {
    // 複数行での省略の場合はheightを設定する
    var height = parseInt(getComputedStyle(el).lineHeight, 10) * lines;
    if (binding.modifiers.flexible) {
      el.style.maxHeight = "".concat(height, "px");
    } else {
      el.style.height = "".concat(height, "px");
    }
  }
  el.dataset.lineClamp = String(lines);
};
var onEllipsisHover = function onEllipsisHover(e) {
  var el = e.currentTarget;
  var styles = getComputedStyle(el);

  // 1行省略のCSSスタイルを持っているか
  var hasSingleLineStype = styles.textOverflow === 'ellipsis' && styles.whiteSpace === 'nowrap';

  // 複数行省略のCSSスタイルを持っているか
  var hasMultiLineStype = styles.textOverflow === 'ellipsis' && parseInt(styles.webkitLineClamp, 10) > 1 && styles.webkitBoxOrient === 'vertical' && ['-webkit-box', 'flow-root'].includes(styles.display);
  if (hasSingleLineStype && el.offsetWidth < el.scrollWidth || hasMultiLineStype && el.offsetHeight < el.scrollHeight) {
    // 省略用のCSSがついていて、かつ省略状態にある場合にツールチップを表示
    showTooltip(el, el.innerText.replace(/^(\r\n|\n)/, ''));
  }
};
var onEllipsisBlur = function onEllipsisBlur(e) {
  var el = e.currentTarget;
  hideTooltip(el);
};
export default defineNuxtPlugin(function (nuxt) {
  nuxt.vueApp.directive('ellipsis', {
    bind: function bind(el, binding, vnode) {
      if (binding.value === false) return;
      el.addEventListener('mouseenter', onEllipsisHover);
      el.addEventListener('mouseleave', onEllipsisBlur);
      el.addEventListener('mouseout', onEllipsisBlur);
      el.addEventListener('pointerleave', onEllipsisBlur);
    },
    inserted: function inserted(el, binding, vnode) {
      _update(el, binding);
    },
    update: function update(el, binding, vnode) {
      _update(el, binding);
    },
    unbind: function unbind(el, binding, vnode) {
      if (binding.value === false) return;
      el.removeEventListener('mouseenter', onEllipsisHover);
      el.removeEventListener('mouseleave', onEllipsisBlur);
      el.removeEventListener('mouseout', onEllipsisBlur);
      el.removeEventListener('pointerleave', onEllipsisBlur);
      hideTooltip(el);
    }
  });
});