import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var AttachmentArea = function AttachmentArea() {
  return import('../../src/components/attachment/AttachmentArea.vue' /* webpackChunkName: "components/attachment-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentDialog = function AttachmentDialog() {
  return import('../../src/components/attachment/AttachmentDialog.vue' /* webpackChunkName: "components/attachment-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentItems = function AttachmentItems() {
  return import('../../src/components/attachment/AttachmentItems.vue' /* webpackChunkName: "components/attachment-items" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentItemsFilters = function AttachmentItemsFilters() {
  return import('../../src/components/attachment/AttachmentItemsFilters.vue' /* webpackChunkName: "components/attachment-items-filters" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentPreviewDialog = function AttachmentPreviewDialog() {
  return import('../../src/components/attachment/AttachmentPreviewDialog.vue' /* webpackChunkName: "components/attachment-preview-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FileDropOverlay = function FileDropOverlay() {
  return import('../../src/components/attachment/FileDropOverlay.vue' /* webpackChunkName: "components/file-drop-overlay" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FileUploadConfirmDialog = function FileUploadConfirmDialog() {
  return import('../../src/components/attachment/FileUploadConfirmDialog.vue' /* webpackChunkName: "components/file-upload-confirm-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FileUploadOverlay = function FileUploadOverlay() {
  return import('../../src/components/attachment/FileUploadOverlay.vue' /* webpackChunkName: "components/file-upload-overlay" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var IconButton = function IconButton() {
  return import('../../src/components/button/IconButton.vue' /* webpackChunkName: "components/icon-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextButton = function TextButton() {
  return import('../../src/components/button/TextButton.vue' /* webpackChunkName: "components/text-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AlertDialog = function AlertDialog() {
  return import('../../src/components/dialog/AlertDialog.vue' /* webpackChunkName: "components/alert-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CustomDialog = function CustomDialog() {
  return import('../../src/components/dialog/CustomDialog.vue' /* webpackChunkName: "components/custom-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SelectDialog = function SelectDialog() {
  return import('../../src/components/dialog/SelectDialog.vue' /* webpackChunkName: "components/select-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SimpleDialog = function SimpleDialog() {
  return import('../../src/components/dialog/SimpleDialog.vue' /* webpackChunkName: "components/simple-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentIcon = function AttachmentIcon() {
  return import('../../src/components/icons/AttachmentIcon.vue' /* webpackChunkName: "components/attachment-icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BetaIcon = function BetaIcon() {
  return import('../../src/components/icons/BetaIcon.vue' /* webpackChunkName: "components/beta-icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RequiredIcon = function RequiredIcon() {
  return import('../../src/components/icons/RequiredIcon.vue' /* webpackChunkName: "components/required-icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UnderSubmissionIcon = function UnderSubmissionIcon() {
  return import('../../src/components/icons/UnderSubmissionIcon.vue' /* webpackChunkName: "components/under-submission-icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CoverImage = function CoverImage() {
  return import('../../src/components/img/CoverImage.vue' /* webpackChunkName: "components/cover-image" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CoverImages = function CoverImages() {
  return import('../../src/components/img/CoverImages.vue' /* webpackChunkName: "components/cover-images" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AutoResizeTextArea = function AutoResizeTextArea() {
  return import('../../src/components/input/AutoResizeTextArea.vue' /* webpackChunkName: "components/auto-resize-text-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CustomDatePicker = function CustomDatePicker() {
  return import('../../src/components/input/CustomDatePicker.vue' /* webpackChunkName: "components/custom-date-picker" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DateInput = function DateInput() {
  return import('../../src/components/input/DateInput.vue' /* webpackChunkName: "components/date-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FileDropArea = function FileDropArea() {
  return import('../../src/components/input/FileDropArea.vue' /* webpackChunkName: "components/file-drop-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FilePicker = function FilePicker() {
  return import('../../src/components/input/FilePicker.vue' /* webpackChunkName: "components/file-picker" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Input = function Input() {
  return import('../../src/components/input/Input.vue' /* webpackChunkName: "components/input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TwCheckbox = function TwCheckbox() {
  return import('../../src/components/input/TwCheckbox.vue' /* webpackChunkName: "components/tw-checkbox" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TwRadio = function TwRadio() {
  return import('../../src/components/input/TwRadio.vue' /* webpackChunkName: "components/tw-radio" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TwSwitch = function TwSwitch() {
  return import('../../src/components/input/TwSwitch.vue' /* webpackChunkName: "components/tw-switch" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ZipcodeInput = function ZipcodeInput() {
  return import('../../src/components/input/ZipcodeInput.vue' /* webpackChunkName: "components/zipcode-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContextMenu = function ContextMenu() {
  return import('../../src/components/menu/ContextMenu.vue' /* webpackChunkName: "components/context-menu" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContextMenuItem = function ContextMenuItem() {
  return import('../../src/components/menu/ContextMenuItem.vue' /* webpackChunkName: "components/context-menu-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContextMenuItemButton = function ContextMenuItemButton() {
  return import('../../src/components/menu/ContextMenuItemButton.vue' /* webpackChunkName: "components/context-menu-item-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FloatingMenu = function FloatingMenu() {
  return import('../../src/components/menu/FloatingMenu.vue' /* webpackChunkName: "components/floating-menu" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MessageTemplateDialog = function MessageTemplateDialog() {
  return import('../../src/components/menu/MessageTemplateDialog.vue' /* webpackChunkName: "components/message-template-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextEditorContextMenu = function TextEditorContextMenu() {
  return import('../../src/components/menu/TextEditorContextMenu.vue' /* webpackChunkName: "components/text-editor-context-menu" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DecoratableTextArea = function DecoratableTextArea() {
  return import('../../src/components/svg/DecoratableTextArea.vue' /* webpackChunkName: "components/decoratable-text-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var InsertionFrameText = function InsertionFrameText() {
  return import('../../src/components/svg/InsertionFrameText.vue' /* webpackChunkName: "components/insertion-frame-text" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SvgTextEditor = function SvgTextEditor() {
  return import('../../src/components/svg/SvgTextEditor.vue' /* webpackChunkName: "components/svg-text-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SvgTextInput = function SvgTextInput() {
  return import('../../src/components/svg/SvgTextInput.vue' /* webpackChunkName: "components/svg-text-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CustomVDataFooter = function CustomVDataFooter() {
  return import('../../src/components/table/CustomVDataFooter.vue' /* webpackChunkName: "components/custom-v-data-footer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CustomizableHeaderTable = function CustomizableHeaderTable() {
  return import('../../src/components/table/CustomizableHeaderTable.vue' /* webpackChunkName: "components/customizable-header-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OutlinedTable = function OutlinedTable() {
  return import('../../src/components/table/OutlinedTable.vue' /* webpackChunkName: "components/outlined-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SelectDisableableTable = function SelectDisableableTable() {
  return import('../../src/components/table/SelectDisableableTable.vue' /* webpackChunkName: "components/select-disableable-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StyledCellTable = function StyledCellTable() {
  return import('../../src/components/table/StyledCellTable.vue' /* webpackChunkName: "components/styled-cell-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TableTypeSelect = function TableTypeSelect() {
  return import('../../src/components/table/TableTypeSelect.vue' /* webpackChunkName: "components/table-type-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var VerticalTable = function VerticalTable() {
  return import('../../src/components/table/VerticalTable.vue' /* webpackChunkName: "components/vertical-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FrameText = function FrameText() {
  return import('../../src/components/text/FrameText.vue' /* webpackChunkName: "components/frame-text" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LabelText = function LabelText() {
  return import('../../src/components/text/LabelText.vue' /* webpackChunkName: "components/label-text" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LinkText = function LinkText() {
  return import('../../src/components/text/LinkText.vue' /* webpackChunkName: "components/link-text" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SectionSubTitle = function SectionSubTitle() {
  return import('../../src/components/text/SectionSubTitle.vue' /* webpackChunkName: "components/section-sub-title" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SmallLabel = function SmallLabel() {
  return import('../../src/components/text/SmallLabel.vue' /* webpackChunkName: "components/small-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TwLinkText = function TwLinkText() {
  return import('../../src/components/text/TwLinkText.vue' /* webpackChunkName: "components/tw-link-text" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FloatingTooltip = function FloatingTooltip() {
  return import('../../src/components/tooltip/FloatingTooltip.vue' /* webpackChunkName: "components/floating-tooltip" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var HoverableTooltip = function HoverableTooltip() {
  return import('../../src/components/tooltip/HoverableTooltip.vue' /* webpackChunkName: "components/hoverable-tooltip" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddressAlert = function AddressAlert() {
  return import('../../src/components/widgets/AddressAlert.vue' /* webpackChunkName: "components/address-alert" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddressCard = function AddressCard() {
  return import('../../src/components/widgets/AddressCard.vue' /* webpackChunkName: "components/address-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddressLabel = function AddressLabel() {
  return import('../../src/components/widgets/AddressLabel.vue' /* webpackChunkName: "components/address-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddressList = function AddressList() {
  return import('../../src/components/widgets/AddressList.vue' /* webpackChunkName: "components/address-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentCard = function AttachmentCard() {
  return import('../../src/components/widgets/AttachmentCard.vue' /* webpackChunkName: "components/attachment-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttachmentDummyCard = function AttachmentDummyCard() {
  return import('../../src/components/widgets/AttachmentDummyCard.vue' /* webpackChunkName: "components/attachment-dummy-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CrmObjectSyncAlert = function CrmObjectSyncAlert() {
  return import('../../src/components/widgets/CrmObjectSyncAlert.vue' /* webpackChunkName: "components/crm-object-sync-alert" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CrmObjectSyncInfoSection = function CrmObjectSyncInfoSection() {
  return import('../../src/components/widgets/CrmObjectSyncInfoSection.vue' /* webpackChunkName: "components/crm-object-sync-info-section" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditCrmDialog = function EditCrmDialog() {
  return import('../../src/components/widgets/EditCrmDialog.vue' /* webpackChunkName: "components/edit-crm-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GiftPageEditor = function GiftPageEditor() {
  return import('../../src/components/widgets/GiftPageEditor.vue' /* webpackChunkName: "components/gift-page-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GiftPagePreview = function GiftPagePreview() {
  return import('../../src/components/widgets/GiftPagePreview.vue' /* webpackChunkName: "components/gift-page-preview" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductCard = function ProductCard() {
  return import('../../src/components/widgets/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductDummyCard = function ProductDummyCard() {
  return import('../../src/components/widgets/ProductDummyCard.vue' /* webpackChunkName: "components/product-dummy-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductSummary = function ProductSummary() {
  return import('../../src/components/widgets/ProductSummary.vue' /* webpackChunkName: "components/product-summary" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductVariantCard = function ProductVariantCard() {
  return import('../../src/components/widgets/ProductVariantCard.vue' /* webpackChunkName: "components/product-variant-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SelectDraftOrderDialog = function SelectDraftOrderDialog() {
  return import('../../src/components/widgets/SelectDraftOrderDialog.vue' /* webpackChunkName: "components/select-draft-order-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ShippingDateSelect = function ShippingDateSelect() {
  return import('../../src/components/widgets/ShippingDateSelect.vue' /* webpackChunkName: "components/shipping-date-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ShippingInfoArea = function ShippingInfoArea() {
  return import('../../src/components/widgets/ShippingInfoArea.vue' /* webpackChunkName: "components/shipping-info-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ShippingStatusTag = function ShippingStatusTag() {
  return import('../../src/components/widgets/ShippingStatusTag.vue' /* webpackChunkName: "components/shipping-status-tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TagSelect = function TagSelect() {
  return import('../../src/components/widgets/TagSelect.vue' /* webpackChunkName: "components/tag-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TouchSummary = function TouchSummary() {
  return import('../../src/components/widgets/TouchSummary.vue' /* webpackChunkName: "components/touch-summary" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TouchTextViewer = function TouchTextViewer() {
  return import('../../src/components/widgets/TouchTextViewer.vue' /* webpackChunkName: "components/touch-text-viewer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TouchVariants = function TouchVariants() {
  return import('../../src/components/widgets/TouchVariants.vue' /* webpackChunkName: "components/touch-variants" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AlertArea = function AlertArea() {
  return import('../../src/components/AlertArea.vue' /* webpackChunkName: "components/alert-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContentArea = function ContentArea() {
  return import('../../src/components/ContentArea.vue' /* webpackChunkName: "components/content-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormHighlight = function FormHighlight() {
  return import('../../src/components/FormHighlight.vue' /* webpackChunkName: "components/form-highlight" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Loading = function Loading() {
  return import('../../src/components/Loading.vue' /* webpackChunkName: "components/loading" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PhonePreview = function PhonePreview() {
  return import('../../src/components/PhonePreview.vue' /* webpackChunkName: "components/phone-preview" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Qrcode = function Qrcode() {
  return import('../../src/components/Qrcode.vue' /* webpackChunkName: "components/qrcode" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SkeletonLoader = function SkeletonLoader() {
  return import('../../src/components/SkeletonLoader.vue' /* webpackChunkName: "components/skeleton-loader" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SplitView = function SplitView() {
  return import('../../src/components/SplitView.vue' /* webpackChunkName: "components/split-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Tag = function Tag() {
  return import('../../src/components/Tag.vue' /* webpackChunkName: "components/tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}