export default defineNuxtPlugin(function (_ref) {
  var $vuetify = _ref.$vuetify;
  var observer = new MutationObserver(function (records) {
    var surfaceColor = getComputedStyle(document.body).getPropertyValue('--v-color-surface-base');
    var isDark = surfaceColor !== '#ffffff';
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  });

  // 監視の開始
  observer.observe(document.querySelector('head'), {
    childList: true
  });
});