import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.isInvalidUrl ? _c('div', [_vm.$slots.default ? [_vm._t("default")] : [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]], 2) : _setup.isExternalLink ? _c('a', {
    attrs: {
      "href": _vm.to,
      "target": "_blank",
      "rel": "noopener noreferrer"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _setup.openWindow.apply(null, arguments);
      }
    }
  }, [_vm.$slots.default ? [_vm._t("default")] : [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")], _vm._v(" "), _vm.externalIcon ? _c(VIcon, {
    staticStyle: {
      "color": "inherit",
      "vertical-align": "text-top"
    },
    attrs: {
      "size": "8"
    },
    domProps: {
      "textContent": _vm._s(_vm.externalIcon)
    }
  }) : _vm._e()], 2) : _c('nuxt-link', {
    attrs: {
      "to": _vm.to
    },
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
      },
      "auxclick": function auxclick($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.$slots.default ? [_vm._t("default")] : [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };