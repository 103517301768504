import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VApp, {
    attrs: {
      "id": "404"
    }
  }, [_vm.error.statusCode === 404 ? [_c(VContainer, {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "text-md-center"
  }, [_c('h1', [_vm._v("404")]), _vm._v(" "), _c('h2', {
    staticClass: "my-3 text-h5"
  }, [_vm._v("Sorry, page not found")]), _vm._v(" "), _c('div', [_c('nuxt-link', {
    staticClass: "tw-btn tw-btn-fill-primary !tw-inline-flex",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("\n              Go Home\n            ")])], 1)])])], 1)] : _vm._e(), _vm._v(" "), _vm.error.statusCode === 403 ? [_c(VContainer, {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "mr-3 hidden-sm-and-down"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/static/error/403.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "text-md-center"
  }, [_c('h1', [_vm._v("403")]), _vm._v(" "), _c('h2', {
    staticClass: "my-3 text-h5"
  }, [_vm._v("Sorry, access denied.")])])])], 1)] : _vm._e(), _vm._v(" "), _vm.error.statusCode === 500 ? [_c(VContainer, {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "mr-3 hidden-sm-and-down"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/static/error/500.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "text-md-center"
  }, [_c('h1', [_vm._v("500")]), _vm._v(" "), _c('h2', {
    staticClass: "my-3 text-h5"
  }, [_vm._v("Sorry, the server is down.")])])])], 1)] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };