import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin(function (nuxt) {
  var _useRuntimeConfig = useRuntimeConfig(),
    sentryDsn = _useRuntimeConfig.sentryDsn,
    buildId = _useRuntimeConfig.buildId,
    environment = _useRuntimeConfig.environment,
    debug = _useRuntimeConfig.debug;
  var enabled = !!buildId && !debug;
  Sentry.init({
    app: nuxt.vueApp,
    dsn: sentryDsn,
    enabled: enabled,
    environment: environment !== null && environment !== void 0 ? environment : undefined,
    release: buildId,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications', 'The user aborted a request', 'signal is aborted', '401'],
    integrations: [new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter(), {
        routeLabel: 'name'
      })
    })],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 1,
    logErrors: false
  });
  return {
    provide: {
      sentry: {
        captureException: function captureException(exception) {
          if (enabled) {
            Sentry.captureException(exception);
          }
        }
      }
    }
  };
});