import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VFadeTransition, {
    attrs: {
      "leave-absolute": !_vm.modal
    }
  }, [_vm.value ? _c(VOverlay, {
    attrs: {
      "absolute": !_vm.modal,
      "opacity": _vm.modal ? 0.2 : 0,
      "z-index": "10"
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };