import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { cloneDeep, debounce } from 'lodash-es';
export var useHistoryStore = defineStore('history', function () {
  var _history$state$tableO, _history$state, _history$state$filter, _history$state2;
  var tableOptions = ref((_history$state$tableO = (_history$state = history.state) === null || _history$state === void 0 ? void 0 : _history$state.tableOptions) !== null && _history$state$tableO !== void 0 ? _history$state$tableO : null);
  var getTableOptions = function getTableOptions(defaults) {
    var ret = tableOptions.value;
    if (ret == null) tableOptions.value = defaults !== null && defaults !== void 0 ? defaults : {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    };
    return tableOptions.value;
  };
  var saveTableOptions = function saveTableOptions(value) {
    tableOptions.value = value;
  };
  var filterOptions = ref((_history$state$filter = (_history$state2 = history.state) === null || _history$state2 === void 0 ? void 0 : _history$state2.filterOptions) !== null && _history$state$filter !== void 0 ? _history$state$filter : null);
  var getFilterOptions = function getFilterOptions(defaults) {
    var ret = filterOptions.value;
    if (ret == null) filterOptions.value = defaults !== null && defaults !== void 0 ? defaults : {};
    return filterOptions.value;
  };
  var saveFilterOptions = function saveFilterOptions(value) {
    filterOptions.value = value;
  };
  watch(function () {
    return tableOptions.value;
  }, function (newValue, oldValue) {
    window.history.replaceState(_objectSpread(_objectSpread({}, window.history.state), {}, {
      tableOptions: cloneDeep(newValue)
    }), '');
  }, {
    deep: true
  });
  watch(function () {
    return filterOptions.value;
  }, function (newValue, oldValue) {
    if (oldValue != null) {
      var _tableOptions$value;
      var currentPage = (_tableOptions$value = tableOptions.value) === null || _tableOptions$value === void 0 ? void 0 : _tableOptions$value.page;
      if (typeof currentPage === 'number' && currentPage > 1) {
        tableOptions.value = _objectSpread(_objectSpread(_objectSpread({}, {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false
        }), tableOptions.value), {}, {
          page: 1
        });
      }
    }
    window.history.replaceState(_objectSpread(_objectSpread({}, window.history.state), {}, {
      filterOptions: cloneDeep(newValue)
    }), '');
  }, {
    deep: true
  });
  var watchCallback = ref(null);
  watch([tableOptions, filterOptions], debounce(function (value, oldValue, onCleanup) {
    var _watchCallback$value;
    (_watchCallback$value = watchCallback.value) === null || _watchCallback$value === void 0 ? void 0 : _watchCallback$value.call(watchCallback);
  }, 200), {
    deep: true
  });
  var onOptionsChanged = function onOptionsChanged(fn) {
    watchCallback.value = fn;
  };
  useRouter().afterEach(function () {
    var _window$history$state, _window$history$state2, _window$history$state3, _window$history$state4;
    var table = (_window$history$state = (_window$history$state2 = window.history.state) === null || _window$history$state2 === void 0 ? void 0 : _window$history$state2.tableOptions) !== null && _window$history$state !== void 0 ? _window$history$state : null;
    var filter = (_window$history$state3 = (_window$history$state4 = window.history.state) === null || _window$history$state4 === void 0 ? void 0 : _window$history$state4.filterOptions) !== null && _window$history$state3 !== void 0 ? _window$history$state3 : null;
    watchCallback.value = null;
    tableOptions.value = null;
    filterOptions.value = null;
    nextTick(function () {
      tableOptions.value = table;
      filterOptions.value = filter;
    });
  });
  return {
    tableOptions: tableOptions,
    getTableOptions: getTableOptions,
    saveTableOptions: saveTableOptions,
    filterOptions: filterOptions,
    getFilterOptions: getFilterOptions,
    saveFilterOptions: saveFilterOptions,
    onOptionsChanged: onOptionsChanged
  };
});