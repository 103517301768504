import { VFooter } from 'vuetify/lib/components/VFooter';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VFooter, {
    staticClass: "pa-3 app--footer flex-column",
    attrs: {
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center text-caption"
  }, [_vm.publicPage ? [_c('LinkText', {
    staticClass: "secondary-text--text text-decoration-none",
    attrs: {
      "to": "https://sendwow.jp",
      "text": "Powered By SendWOW",
      "external-icon": null
    }
  }), _vm._v("\n       \n    ")] : _vm._e(), _vm._v(" "), _c('LinkText', {
    staticClass: "secondary-text--text text-decoration-none",
    attrs: {
      "to": "https://smapo.co",
      "text": _vm.$config.public.copyright,
      "external-icon": null
    }
  })], 2), _vm._v(" "), _vm.footerMenu ? _c('div', {
    staticClass: "d-flex justify-center flex-wrap"
  }, _vm._l(_vm.links, function (link) {
    return _c('LinkText', {
      key: link.text,
      staticClass: "text-caption secondary-text--text text-decoration-none mx-1",
      attrs: {
        "to": link.href,
        "text": link.text,
        "external-icon": null
      }
    });
  }), 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };