import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { cloneDeep } from 'lodash-es';
export var useSettingsStore = defineStore('settings', function () {
  var drawer = ref(true);
  function toggleDrawer() {
    drawer.value = !drawer.value;
  }
  var hideHeaders = ref({});
  function getHideHeaders(key) {
    var _hideHeaders$value$ke;
    return (_hideHeaders$value$ke = hideHeaders.value[key]) !== null && _hideHeaders$value$ke !== void 0 ? _hideHeaders$value$ke : [];
  }
  function updateHideHeaders(key, headers) {
    hideHeaders.value = _objectSpread(_objectSpread({}, cloneDeep(hideHeaders.value)), {}, _defineProperty({}, key, headers));
  }
  var touchesDisplayType = ref({});
  function getTouchesDisplayType(key) {
    var _touchesDisplayType$v;
    return (_touchesDisplayType$v = touchesDisplayType.value[key]) !== null && _touchesDisplayType$v !== void 0 ? _touchesDisplayType$v : 'grid';
  }
  function updateTouchesDisplayType(key, type) {
    touchesDisplayType.value = _objectSpread(_objectSpread({}, cloneDeep(touchesDisplayType.value)), {}, _defineProperty({}, key, type));
  }
  var debugShowOverflow = ref(false);
  function setDebugShowOverflow(value) {
    debugShowOverflow.value = value;
  }
  var debugEnableCounter = ref(false);
  function setDebugEnableCounter(value) {
    debugEnableCounter.value = value;
  }
  var debugPatchPhoneNumber = ref(false);
  function setDebugPatchPhoneNumber(value) {
    debugPatchPhoneNumber.value = value;
  }
  return {
    drawer: drawer,
    toggleDrawer: toggleDrawer,
    hideHeaders: hideHeaders,
    getHideHeaders: getHideHeaders,
    updateHideHeaders: updateHideHeaders,
    touchesDisplayType: touchesDisplayType,
    getTouchesDisplayType: getTouchesDisplayType,
    updateTouchesDisplayType: updateTouchesDisplayType,
    debugShowOverflow: debugShowOverflow,
    setDebugShowOverflow: setDebugShowOverflow,
    debugEnableCounter: debugEnableCounter,
    setDebugEnableCounter: setDebugEnableCounter,
    debugPatchPhoneNumber: debugPatchPhoneNumber,
    setDebugPatchPhoneNumber: setDebugPatchPhoneNumber
  };
}, {
  persist: true
});