import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.starts-with.js";
function isExtensionTargetOrigin(origin) {
  return /^https:\/\/.*\.lightning\.force\.com$/.test(origin) || /^https:\/\/.*\.my\.salesforce\.com$/.test(origin) || origin === 'https://app.hubspot.com';
}
export default defineNuxtPlugin(function () {
  return {
    provide: {
      fromExtension: function fromExtension() {
        if (useRuntimeConfig().debug) {
          try {
            // developで urlに extension=true がついている場合 or toolsからのアクセスの場合は常にtrueにする
            if (useRoute().query.extension === 'true' || window.parent.location.pathname.startsWith('/tools/extension')) {
              return true;
            }
          } catch (error) {}
        }
        var parentOrigin;
        try {
          parentOrigin = window.parent.origin;
        } catch (error) {
          parentOrigin = null;
        }
        return window !== window.parent && window.origin !== parentOrigin;
      },
      canOpenFromExtension: function canOpenFromExtension() {
        if (useRuntimeConfig().debug) {
          try {
            // developで urlに extension=true がついている場合 or toolsからのアクセスの場合は常にtrueにする
            if (useRoute().query.extension === 'true' || window.parent.location.pathname.startsWith('/tools/extension')) {
              return true;
            }
          } catch (error) {}
        }
        return window.location.ancestorOrigins.length === 1 && isExtensionTargetOrigin(window.location.ancestorOrigins[0]);
      },
      isLoginPage: function isLoginPage(routeName) {
        var name = routeName !== null && routeName !== void 0 ? routeName : useRoute().name;
        return name === 'login';
      },
      isPublicPage: function isPublicPage(routeName) {
        var name = routeName !== null && routeName !== void 0 ? routeName : useRoute().name;
        return name === 'wow-id' || name === 'wow-id-complete_donation' || name === 'orders-form-id' || name === 'auth_callback';
      }
    }
  };
});