import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm$currentTeam$name, _vm$currentTeam;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VNavigationDrawer, {
    attrs: {
      "id": "appDrawer",
      "value": "true",
      "mini-variant": _vm.mini,
      "app": "",
      "fixed": "",
      "stateless": "",
      "width": "240",
      "mini-variant-width": "72"
    }
  }, [_c(VList, {
    attrs: {
      "id": "menu_user",
      "two-line": ""
    }
  }, [_c(VListItem, {
    class: {
      'px-2': _vm.mini
    }
  }, [_c(VListItemAvatar, {
    attrs: {
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("\n          " + _vm._s(_vm.initial) + "\n        ")])]), _vm._v(" "), _c(VListItemContent, [_c(VListItemTitle, {
    staticClass: "text-subtitle-1 mb-2"
  }, [_vm._v("\n          " + _vm._s(_vm.$fullName(_vm.user)) + "\n        ")]), _vm._v(" "), _c(VListItemSubtitle, {
    staticClass: "secondary-text--text"
  }, [_vm._v("\n          " + _vm._s((_vm$currentTeam$name = (_vm$currentTeam = _vm.currentTeam) === null || _vm$currentTeam === void 0 ? void 0 : _vm$currentTeam.name) !== null && _vm$currentTeam$name !== void 0 ? _vm$currentTeam$name : '') + "\n        ")])], 1), _vm._v(" "), !_vm.$vuetify.breakpoint.mdAndDown ? _c(VListItemAction, [_c(VIcon, {
    attrs: {
      "dense": ""
    },
    on: {
      "click": _vm.toggleDrawer
    }
  }, [_vm._v("\n          " + _vm._s(_vm.drawer ? 'mdi-chevron-left' : 'mdi-chevron-right') + "\n        ")])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "mb-2",
    class: _vm.mini ? 'mx-2' : 'mx-4'
  }), _vm._v(" "), _c(VList, {
    attrs: {
      "dense": "",
      "expand": ""
    }
  }, [_c(VListItemGroup, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.menus, function (menu) {
    return _c(VListItem, {
      key: menu.title,
      class: {
        'pa-0': _vm.mini
      },
      attrs: {
        "to": menu.to
      }
    }, [_vm.mini ? [_c('div', {
      staticClass: "d-flex flex-column align-center pt-1 pb-1"
    }, [_c(VBadge, {
      attrs: {
        "value": menu.badge > 0,
        "color": "primary",
        "content": menu.badge
      }
    }, [_c(VIcon, {
      attrs: {
        "size": "24"
      },
      domProps: {
        "textContent": _vm._s(menu.icon)
      }
    })], 1), _vm._v(" "), _c('pre', {
      staticClass: "text-overline text-center mt-1",
      domProps: {
        "textContent": _vm._s(menu.title)
      }
    })], 1)] : [_c(VListItemIcon, {
      staticClass: "align-self-center"
    }, [_c(VIcon, {
      attrs: {
        "size": "24"
      },
      domProps: {
        "textContent": _vm._s(menu.icon)
      }
    })], 1), _vm._v(" "), _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "text-body-1 d-flex"
    }, [_vm._v("\n              " + _vm._s(_vm.$singleLine(menu.title)) + "\n              "), menu.badge > 0 ? _c(VBadge, {
      staticClass: "ml-1",
      attrs: {
        "content": menu.badge,
        "color": "primary",
        "inline": ""
      }
    }) : _vm._e()], 1)], 1)]], 2);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };