import _typeof from "@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.number.constructor.js";
export default defineNuxtPlugin(function (nuxtApp) {
  return {
    provide: {
      price: function price(value) {
        if (typeof value === 'number') {
          return nuxtApp.$t('price', [value.toLocaleString()]);
        } else if (typeof value === 'undefined' || value === null) {
          return '';
        } else if (_typeof(value) === 'object' && typeof value.min === 'number') {
          if (value.min === value.max) {
            return nuxtApp.$t('price', [value.min.toLocaleString()]);
          } else {
            return nuxtApp.$t('minPrice', [value.min.toLocaleString()]);
          }
        } else if (typeof value === 'string') {
          var number = Number(value); // number型と合わせて1000以上の場合にカンマをつけたいので、一旦number型に戻す
          if (!isNaN(number)) {
            return nuxtApp.$t('price', [number.toLocaleString()]);
          } else {
            return nuxtApp.$t('price', [value]);
          }
        } else {
          throw new TypeError('price format error');
        }
      }
    }
  };
});