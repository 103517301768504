import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.string.small.js";
var render = function render() {
  var _vm$attributes$color;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VBtn, _vm._g(_vm._b({
    staticClass: "pa-0",
    class: {
      'text-subtitle-2': !_vm.xSmall,
      'text-caption': _vm.xSmall
    },
    attrs: {
      "text": "",
      "color": (_vm$attributes$color = _vm.attributes.color) !== null && _vm$attributes$color !== void 0 ? _vm$attributes$color : 'primary'
    }
  }, 'v-btn', _vm.attributes, false), _vm.$listeners), [_vm.icon ? _c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "x-small": _vm.xSmall,
      "small": _vm.small
    },
    domProps: {
      "textContent": _vm._s(_vm.icon)
    }
  }) : _vm._e(), _vm._v("\n  " + _vm._s(_vm.value) + "\n  "), _vm.isExternalLink && _vm.externalIcon ? _c(VIcon, {
    staticClass: "align-self-start ml-0.5",
    attrs: {
      "size": "8"
    },
    domProps: {
      "textContent": _vm._s(_vm.externalIcon)
    }
  }) : _vm._e(), _vm._v(" "), _vm.appendIcon ? _c(VIcon, {
    staticClass: "ml-1",
    attrs: {
      "x-small": _vm.xSmall,
      "small": _vm.small
    },
    domProps: {
      "textContent": _vm._s(_vm.appendIcon)
    }
  }) : _vm._e(), _vm._v(" "), _vm.$slots.append ? _vm._t("append") : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };