export const isOrder = (arg: unknown): arg is Order =>
  typeof arg === 'object' &&
  arg !== null &&
  'status' in arg &&
  ['number', 'undefined'].includes(typeof arg.status) &&
  'get_status_display' in arg &&
  typeof arg.get_status_display === 'string' &&
  'line_items' in arg &&
  Array.isArray(arg.line_items) &&
  arg.line_items.every(isLineItem)

export const isLineItem = (arg: unknown): arg is LineItem =>
  typeof arg === 'object' &&
  arg !== null &&
  'touch' in arg &&
  typeof arg.touch === 'string'

export const isOrderSammary = (arg: unknown): arg is OrderSummary =>
  typeof arg === 'object' &&
  arg !== null &&
  'status' in arg &&
  ['number', 'undefined'].includes(typeof arg.status) &&
  'get_status_display' in arg &&
  typeof arg.get_status_display === 'string' &&
  'order_line_items' in arg &&
  Array.isArray(arg.order_line_items) &&
  arg.order_line_items.every(isOrderLineItem)

export const isOrderLineItem = (arg: unknown): arg is OrderLineItem =>
  typeof arg === 'object' &&
  arg !== null &&
  'line_item_lp' in arg &&
  typeof arg.line_item_lp === 'object'

export const isTouch = (arg: unknown): arg is TouchInfo =>
  typeof arg === 'object' &&
  arg !== null &&
  'name' in arg &&
  typeof arg.name === 'string' &&
  'shopify_product' in arg &&
  isProductWithVariant(arg.shopify_product)

export const isCampaign = (arg: unknown): arg is Campaign =>
  typeof arg === 'object' &&
  arg !== null &&
  'id' in arg &&
  typeof arg.id === 'string' &&
  'name' in arg &&
  typeof arg.name === 'string' &&
  'owner' in arg &&
  typeof arg.owner === 'string' &&
  'total_cost' in arg &&
  typeof arg.total_cost === 'number' &&
  'order_count' in arg &&
  typeof arg.order_count === 'number'

export const isCustomText = (arg: unknown): arg is CustomText =>
  typeof arg === 'object' &&
  arg !== null &&
  'id' in arg &&
  typeof arg.id === 'string' &&
  'custom_id' in arg &&
  typeof arg.custom_id === 'string' &&
  'text' in arg &&
  typeof arg.text === 'string'

export const isProduct = (arg: unknown): arg is Product =>
  typeof arg === 'object' &&
  arg !== null &&
  'product_id' in arg &&
  typeof arg.product_id === 'string' &&
  'product_title' in arg &&
  typeof arg.product_title === 'string'

export const isProductWithVariant = (arg: unknown): arg is ProductWithVariant =>
  isProduct(arg) &&
  'variant_title' in arg &&
  typeof arg.variant_title === 'string'

export const isAttachment = (arg: unknown): arg is Attachment =>
  typeof arg === 'object' &&
  arg !== null &&
  'id' in arg &&
  typeof arg.id === 'string' &&
  'file_name' in arg &&
  typeof arg.file_name === 'string' &&
  'file_size' in arg &&
  typeof arg.file_size === 'number'

export function isCrmInstanceContact(
  arg: Contact | CrmInstanceContact | null | undefined
): arg is CrmInstanceContact {
  return 'instance_status' in (arg ?? {})
}

export function isContactSnapshot(arg: unknown): arg is ContactSnapshot {
  return (
    typeof arg === 'object' &&
    arg !== null &&
    'last_name' in arg &&
    typeof arg.last_name === 'string' &&
    'first_name' in arg &&
    (arg.first_name === null || typeof arg.first_name === 'string') &&
    'account' in arg &&
    (arg.account === null || typeof arg.account === 'string') &&
    'crm_id' in arg &&
    (arg.crm_id === null || typeof arg.crm_id === 'string')
  )
}

export function isAccount(arg: unknown): arg is Account {
  return (
    typeof arg === 'object' &&
    arg !== null &&
    'id' in arg &&
    typeof arg.id === 'string' &&
    'name' in arg &&
    typeof arg.name === 'string' &&
    'shipping_addresses' in arg &&
    Array.isArray(arg.shipping_addresses) &&
    arg.shipping_addresses.every(isAddress)
  )
}

export const isAddress = (arg: unknown): arg is Address =>
  typeof arg === 'object' &&
  arg !== null &&
  'postcode' in arg &&
  (arg.postcode === null || typeof arg.postcode === 'string') &&
  'pref' in arg &&
  (arg.pref === null || typeof arg.pref === 'string') &&
  'city' in arg &&
  (arg.city === null || typeof arg.city === 'string') &&
  'street1' in arg &&
  (arg.street1 === null || typeof arg.street1 === 'string')

export const isAnnouncement = (arg: unknown): arg is Announcement =>
  typeof arg === 'object' &&
  arg !== null &&
  ['info', 'warn'].includes((arg as Announcement).type) &&
  'text' in arg &&
  typeof arg.text === 'string' &&
  'link' in arg &&
  typeof arg.link in ['string', 'undefined']
