import "core-js/modules/es.function.name.js";
export default {
  computed: {
    breadscrumbs: function breadscrumbs() {
      return {
        index: [{
          text: 'ホーム',
          disabled: true
        }],
        campaign: [{
          text: 'キャンペーン',
          disabled: true
        }],
        'campaign-id': [{
          text: 'キャンペーン',
          to: '/campaign'
        }, {
          text: '詳細',
          disabled: true
        }],
        'campaign-send': [{
          text: 'キャンペーン',
          to: '/campaign'
        }, {
          text: 'キャンペーンの作成',
          disabled: true
        }],
        'campaign-send-check': [{
          text: 'キャンペーン',
          to: '/campaign'
        }, {
          text: 'キャンペーンの作成',
          to: '/campaign/send'
        }, {
          text: 'キャンペーンの確認',
          disabled: true
        }],
        contact: [{
          text: 'コンタクト',
          disabled: true
        }],
        'contact-id': [{
          text: 'コンタクト',
          to: '/contact/'
        }, {
          text: '詳細',
          disabled: true
        }],
        'contact-id-send': [{
          text: 'コンタクト',
          to: '/contact/'
        }, {
          text: '詳細',
          to: {
            name: 'contact-id',
            params: this.$route.params
          }
        }, {
          text: 'オーダーの作成',
          disabled: true
        }],
        'contact-id-send-check': [{
          text: 'コンタクト',
          to: '/contact/'
        }, {
          text: '詳細',
          to: {
            name: 'contact-id',
            params: this.$route.params
          }
        }, {
          text: 'オーダーの作成',
          to: {
            name: 'contact-id-send',
            params: this.$route.params
          }
        }, {
          text: 'オーダーの確認',
          disabled: true
        }],
        settings: [{
          text: '設定',
          disabled: true
        }],
        'settings-team-info': [{
          text: '設定',
          disabled: true
        }],
        'settings-message-template': [{
          text: '設定',
          disabled: true
        }],
        'settings-integration-salesforce': [{
          text: '設定',
          to: '/settings/team-info'
        }, {
          text: 'Salesforce連携の設定',
          disabled: true
        }],
        'settings-integration-hubspot': [{
          text: '設定',
          to: '/settings/team-info'
        }, {
          text: 'HubSpot連携の設定',
          disabled: true
        }],
        'settings-integration-slack': [{
          text: '設定',
          to: '/settings/team-info'
        }, {
          text: 'Slack連携の設定',
          disabled: true
        }],
        'settings-integration-chatwork': [{
          text: '設定',
          to: '/settings/team-info'
        }, {
          text: 'Chatwork連携の設定',
          disabled: true
        }],
        help: [{
          text: 'ヘルプ',
          disabled: true
        }],
        announcement: [{
          text: 'お知らせ',
          disabled: true
        }],
        marketplace: [{
          text: 'マーケットプレイス',
          disabled: true
        }],
        touch: [{
          text: 'タッチ',
          disabled: true
        }],
        'touch-id': [{
          text: 'タッチ',
          to: '/touch'
        }, {
          text: '詳細',
          disabled: true
        }],
        'touch-id-edit': [{
          text: 'タッチ',
          to: '/touch'
        }, {
          text: '詳細',
          to: {
            name: 'touch-id',
            params: this.$route.params
          }
        }, {
          text: 'タッチの編集',
          disabled: true
        }],
        'touch-new': [{
          text: 'タッチ',
          to: '/touch'
        }, {
          text: 'タッチの作成',
          disabled: true
        }],
        orders: [{
          text: 'オーダー',
          disabled: true
        }],
        'orders-id': [{
          text: 'オーダー',
          to: '/orders'
        }, {
          text: '詳細',
          disabled: true
        }],
        'orders-id-edit': [{
          text: 'オーダー',
          to: '/orders'
        }, {
          text: '詳細',
          to: {
            name: 'orders-id',
            params: {
              id: this.$route.params.order_id
            }
          }
        }, {
          text: 'オーダーの編集',
          disabled: true
        }],
        accounts: [{
          text: '会社',
          disabled: true
        }],
        'accounts-id': [{
          text: '会社',
          to: '/accounts/'
        }, {
          text: '詳細',
          disabled: true
        }]
      };
    },
    breadcrumbs: function breadcrumbs() {
      return this.breadscrumbs[this.$route.name];
    }
  }
};