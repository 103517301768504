import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
/* eslint-disable camelcase */

export default defineNuxtPlugin(function (nuxtApp) {
  return {
    provide: {
      zipcode: function zipcode(postcode) {
        if (!postcode) {
          return '';
        }
        postcode = postcode.replace(/^〒/, '');
        if (/^(\d{3})-(\d{4})$/.test(postcode)) {
          return nuxtApp.$t('zipcode2', [postcode]);
        } else if (nuxtApp.$isValidZipcode(postcode, false)) {
          return nuxtApp.$t('zipcode', [postcode.slice(0, 3), postcode.slice(3, postcode.length)]);
        }
        return nuxtApp.$t('zipcode2', [postcode]);
      },
      address: function address(value) {
        var _ref = value !== null && value !== void 0 ? value : {},
          postcode = _ref.postcode,
          pref = _ref.pref,
          city = _ref.city,
          street1 = _ref.street1,
          _ref$street = _ref.street2,
          street2 = _ref$street === void 0 ? '' : _ref$street;
        var list = [nuxtApp.$zipcode(postcode !== null && postcode !== void 0 ? postcode : null), pref !== null && pref !== void 0 ? pref : '', city !== null && city !== void 0 ? city : '', street1 !== null && street1 !== void 0 ? street1 : '', street2 !== null && street2 !== void 0 ? street2 : ''].filter(function (it) {
          return it.length;
        });
        return list.join(' ').replace(/\s+/, ' ').trim();
      },
      fullName: function fullName(value) {
        var _ref2 = value !== null && value !== void 0 ? value : {},
          first_name = _ref2.first_name,
          last_name = _ref2.last_name;
        if (first_name && last_name) {
          return nuxtApp.$t('fullName', {
            first_name: first_name,
            last_name: last_name
          });
        } else if (first_name && !last_name) {
          return first_name;
        } else if (!first_name && last_name) {
          return last_name;
        } else {
          return '';
        }
      },
      shippableAddressCount: function shippableAddressCount(value) {
        var _value$account$shippi, _value$account, _value$shipping_addre;
        return ((_value$account$shippi = value === null || value === void 0 ? void 0 : (_value$account = value.account) === null || _value$account === void 0 ? void 0 : _value$account.shipping_addresses) !== null && _value$account$shippi !== void 0 ? _value$account$shippi : []).length + ((_value$shipping_addre = value === null || value === void 0 ? void 0 : value.shipping_addresses) !== null && _value$shipping_addre !== void 0 ? _value$shipping_addre : []).length;
      },
      fillContactToOrder: function fillContactToOrder(order) {
        var _order$contact_snapsh, _snapshot$last_name, _snapshot$account, _snapshot$tags$split$, _snapshot$tags;
        if (order.contact != null) {
          return order;
        }
        var snapshot = (_order$contact_snapsh = order.contact_snapshot) !== null && _order$contact_snapsh !== void 0 ? _order$contact_snapsh : {};
        return _objectSpread(_objectSpread({}, order), {}, {
          contact: {
            id: '',
            last_name: (_snapshot$last_name = snapshot.last_name) !== null && _snapshot$last_name !== void 0 ? _snapshot$last_name : '⚠️ 削除されたコンタクト',
            first_name: snapshot.first_name,
            email: snapshot.email,
            title: nuxtApp.$t('unknown'),
            account: {
              id: '',
              name: (_snapshot$account = snapshot.account) !== null && _snapshot$account !== void 0 ? _snapshot$account : nuxtApp.$t('unknown'),
              site_url: '',
              shipping_addresses: [],
              crm: null,
              crm_id: null,
              crm_url: null,
              sync_status: 'no_sync',
              last_synced_at: null,
              get_crm_display: null
            },
            job_title: snapshot.job_title,
            department: snapshot.department,
            owner: {
              first_name: nuxtApp.$t('unknown'),
              last_name: '',
              email: 'dummy@example.com',
              name: nuxtApp.$t('unknown')
            },
            shipping_addresses: [],
            tags: (_snapshot$tags$split$ = (_snapshot$tags = snapshot.tags) === null || _snapshot$tags === void 0 ? void 0 : _snapshot$tags.split(',').map(function (tag) {
              return tag.trim();
            })) !== null && _snapshot$tags$split$ !== void 0 ? _snapshot$tags$split$ : [],
            crm: snapshot.crm,
            crm_company_name: snapshot.crm_company_name,
            crm_id: snapshot.crm_id,
            crm_url: snapshot.crm_url,
            sync_status: 'no_sync',
            last_synced_at: null,
            get_crm_display: null
          }
        });
      }
    }
  };
});