import { datadogRum } from '@datadog/browser-rum';
export default defineNuxtPlugin(function () {
  var _useRuntimeConfig = useRuntimeConfig(),
    datadogRumApplicationId = _useRuntimeConfig.datadogRumApplicationId,
    datadogRumClientToken = _useRuntimeConfig.datadogRumClientToken,
    environment = _useRuntimeConfig.environment,
    debug = _useRuntimeConfig.debug;

  // とりあえず本番環境以外は集計しないようにする
  if (environment !== 'production') return;
  useRouter().beforeEach(function (to, from, next) {
    var _useUserStore = useUserStore(),
      user = _useUserStore.user,
      currentTeam = _useUserStore.team;
    var isLoggedIn = !!user && !!currentTeam;
    if (isLoggedIn && !datadogRum.getInternalContext()) {
      // ログインしつつ初期化されていないときだけ
      datadogRum.init({
        applicationId: datadogRumApplicationId,
        clientToken: datadogRumClientToken,
        site: 'ap1.datadoghq.com',
        service: 'sendwow',
        env: environment !== null && environment !== void 0 ? environment : 'development',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      if (!debug) datadogRum.startSessionReplayRecording();
    }
    next();
  });
});