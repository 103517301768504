import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import dayjs from 'dayjs';
import { random } from 'lodash-es';
import md5 from 'md5';
var transitionend = function transitionend(el, callback) {
  var handler = function handler() {
    clearTimeout(timeout);
    el.removeEventListener('transitionend', handler);
    callback();
  };

  // アニメーション時間をCSSから算出する
  var styles = getComputedStyle(el);
  var ms = (parseFloat(styles.transitionDelay) + parseFloat(styles.transitionDuration)) * 1000;
  el.addEventListener('transitionend', handler);
  // transitionendが発火しない場合のfallbackとしてアニメーション時間+αでhendlerをコールする
  var timeout = setTimeout(handler, ms + 100);
};
export var showTooltip = function showTooltip(target, text) {
  var _target$dataset$toolt, _document$querySelect;
  hideAllTooltips();
  if (!target) return;
  target.dataset.tooltipId = (_target$dataset$toolt = target.dataset.tooltipId) !== null && _target$dataset$toolt !== void 0 ? _target$dataset$toolt : md5(random(10) + dayjs().toISOString());
  var tooltip = document.createElement('div');
  tooltip.textContent = text;
  tooltip.classList.add('simple-tooltip');
  tooltip.id = target.dataset.tooltipId;
  var rect = target.getBoundingClientRect();
  var top = rect.y + window.scrollY - 2;
  var bottom = top + rect.height + 2;
  var left = rect.x + window.scrollX;
  tooltip.style.maxWidth = rect.width > 100 ? "".concat(rect.width + 16, "px") : '';
  (_document$querySelect = document.querySelector('[data-app]')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.appendChild(tooltip);
  requestAnimationFrame(function () {
    var _tooltip$getBoundingC = tooltip.getBoundingClientRect(),
      tooltopWidth = _tooltip$getBoundingC.width,
      tooltopHeight = _tooltip$getBoundingC.height;

    // ツールチップ表示したら画面外になってしまうかをチェック
    var isOverflowTop = rect.y - tooltopHeight - 2 < 0;
    tooltip.style.top = "".concat(!isOverflowTop ? top - tooltopHeight : bottom, "px");
    tooltip.style.left = "".concat(left - (tooltopWidth - rect.width) / 2, "px");
    requestAnimationFrame(function () {
      tooltip.classList.add('visible');
      requestAnimationFrame(function () {
        return tooltip.classList.add('show');
      });
    });
  });
};
export var hideTooltip = function hideTooltip(target) {
  var _target$dataset$toolt2;
  var tooltip = document.getElementById((_target$dataset$toolt2 = target === null || target === void 0 ? void 0 : target.dataset.tooltipId) !== null && _target$dataset$toolt2 !== void 0 ? _target$dataset$toolt2 : '');
  if (!tooltip) return;
  tooltip.classList.remove('visible');
  tooltip.classList.remove('show');
  transitionend(tooltip, function () {
    return tooltip.remove();
  });
};
export var hideAllTooltips = function hideAllTooltips() {
  document.querySelectorAll('[data-tooltip-id]').forEach(function (node) {
    if (node instanceof HTMLElement) hideTooltip(node);
  });
};
var onHover = function onHover(e) {
  var el = e.currentTarget;
  var tooltopText = el.dataset.tooltip;
  if (!tooltopText) return;
  showTooltip(el, tooltopText);
};
var onBlur = function onBlur(e) {
  var el = e.currentTarget;
  hideTooltip(el);
};
export default defineNuxtPlugin(function (nuxt) {
  nuxt.vueApp.directive('tooltip', {
    bind: function bind(el, binding, vnode) {
      if (!binding.value) return;
      el.dataset.tooltip = binding.value;
      el.addEventListener('mouseenter', onHover);
      el.addEventListener('mouseleave', onBlur);
      el.addEventListener('mouseout', onBlur);
      el.addEventListener('pointerleave', onBlur);
    },
    unbind: function unbind(el, binding, vnode) {
      if (!binding.value) return;
      delete el.dataset.tooltip;
      el.removeEventListener('mouseenter', onHover);
      el.removeEventListener('mouseleave', onBlur);
      el.removeEventListener('mouseout', onBlur);
      el.removeEventListener('pointerleave', onBlur);
      hideTooltip(el);
    }
  });
});