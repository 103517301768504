import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
// Note: tailwindのスタイルがVuetifyの_reset.cssに消されてしまうので強制的に優先度を変える

export default defineNuxtPlugin(function () {
  var tailwindStyles = Array.from(document.querySelectorAll('style')).filter(function (style) {
    var _style$textContent;
    return (_style$textContent = style.textContent) === null || _style$textContent === void 0 ? void 0 : _style$textContent.includes('tailwind');
  });
  tailwindStyles.forEach(function (style) {
    style.remove();
    document.head.appendChild(style);
  });
});